import Card from "./Card";
import PropTypes from "prop-types";
import styles from "./ContentComponentOurServices.module.css";
import { useHistory } from "react-router-dom";

const ContentComponentOurServices = ({ className = "" }) => {
  const history= useHistory();

  function handleContactClick(event) {
    event.preventDefault();
    history.push("/contact", { from: 'thinking' } );
  }
 
  return (
    <section
      className={[styles.contentcomponentOurservices, className].join(" ")}
    >
      <div className={styles.container}>
        <div className={styles.contentwarp}>
          <div className={styles.infomation}>
            <div className={styles.textWrapper}>
              <h1 className={styles.ourServices}>Our Services</h1>
              <div className={styles.weHelpingLandowners}>
                We helping landowners monetise their unused space by connecting
                with drivers looking for convenient, affordable stopovers.
              </div>
            </div>
            <div className={styles.cta}>
              <div className={styles.ctaChild} />
              <button className={styles.locobutton} onClick={handleContactClick}>
                <div className={styles.button}>Contact us today</div>
              </button>
            </div>
          </div>
          <div className={styles.graphicwrapper}>
            <Card
              solidIcon="/solidicon.svg"
              title="Self-serve stopover platform"
              locateAnAireUsingYourPref="Effortless digital management for your motorhome stopover. Book, pay, communicate"
            />
            <Card
              solidIcon="/solidicon-1.svg"
              title={`Site appraisal & feasibility `}
              locateAnAireUsingYourPref="Expert assessment of your land's suitability for a motorhome stopover"
            />
            <Card
              solidIcon="/solidicon-2.svg"
              title={`Site design & Construction`}
              locateAnAireUsingYourPref="Design and build your ideal motorhome stopover"
            />
            <Card
              solidIcon="/solidicon-3.svg"
              title="Complete aire management"
              locateAnAireUsingYourPref="We manage everything. Monetise your space without the management hassle"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

ContentComponentOurServices.propTypes = {
  className: PropTypes.string,
};

export default ContentComponentOurServices;
