import PropTypes from "prop-types";
import styles from "./FeatureSectionHowItWorks.module.css";

const FeatureSectionHowItWorks = ({ className = "" }) => {
  return (
    <section className={[styles.featuresectionHowitworks, className].join(" ")}>
      <div className={styles.content}>
        <div className={styles.titletext}>
          <h1 className={styles.howItWorks}>How it works</h1>
          <div className={styles.parkWithEase}>
            Park with ease, travel with peace – AireStop makes it simple!
          </div>
        </div>
        <div className={styles.cardwrap}>
          <div className={styles.card}>
            <img
              className={styles.featureIcons}
              loading="lazy"
              alt=""
              src="/feature-icons.svg"
            />
            <div className={styles.locateAnAire}>
              Locate an aire using your preferred search platform
            </div>
          </div>
          <div className={styles.card1}>
            <img
              className={styles.featureIcons1}
              alt=""
              src="/feature-icons-1@2x.png"
            />
            <div className={styles.locateAnAire1}>
              On arrival, scan the QR code or enter the site code on our website
              to find the stop profile
            </div>
          </div>
          <div className={styles.card2}>
            <img
              className={styles.featureIcons2}
              alt=""
              src="/feature-icons-2.svg"
            />
            <div className={styles.locateAnAire2}>
              Read the profile to discover what services are available and any
              specific rules set by your host
            </div>
          </div>
          <div className={styles.card3}>
            <img
              className={styles.featureIcons3}
              alt=""
              src="/feature-icons-2.svg"
            />
            <div
              className={styles.locateAnAire3}
            >{`Complete your booking by making a secure payment `}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeatureSectionHowItWorks.propTypes = {
  className: PropTypes.string,
};

export default FeatureSectionHowItWorks;
