import PropTypes from "prop-types";
import styles from "./FeatureSectionTrustedPartners.module.css";

const FeatureSectionTrustedPartners = ({ className = "" }) => {
  return (
    <section
      className={[styles.featuresectionTrustedpartners, className].join(" ")}
    >
      <div className={styles.contrainer}>
        <div className={styles.title}>
          <h1 className={styles.trustedPartners}>Trusted Partners</h1>
        </div>
        <div className={styles.servicepartners}>
          <div className={styles.stripe}>
            <img
              className={styles.partnerLogoIcon}
              loading="lazy"
              alt=""
              src="/partner-logo.svg"
            />
          </div>
          <div className={styles.campra}>
            <div className={styles.partnerLogo}>
              <img
                className={styles.campraUkLogopngIcon}
                loading="lazy"
                alt=""
                src="/campra-uk-logopng@2x.png"
              />
            </div>
          </div>

          <div className={styles.campra}>
            <div className={styles.partnerLogo}>
              <img
                className={styles.campraUkLogopngIcon}
                loading="lazy"
                alt=""
                src="/black-no-background-1@2x.png"
              />
            </div>
          </div>


          <div className={styles.atc}>
            <div className={styles.partnerLogo1}>
              <img
                className={styles.angusTourismCooperativeLogoIcon}
                loading="lazy"
                alt=""
                src="/angus-tourism-cooperative-logopng@2x.png"
              />
            </div>
          </div>
          <div className={styles.sfs}>
            <div className={styles.partnerLogo2}>
              <img
                className={styles.partnerLogoChild}
                loading="lazy"
                alt=""
                src="/frame-1@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeatureSectionTrustedPartners.propTypes = {
  className: PropTypes.string,
};

export default FeatureSectionTrustedPartners;
