import PropTypes from "prop-types";
import styles from "./ContentComponentSpaceNoHost.module.css";
import { useHistory } from "react-router-dom";

const ContentComponentSpaceNoHost = ({ className = "" }) => {
  const history=useHistory();

  function handleContactClick(event) {
    event.preventDefault();
    history.push("/contact", { from: 'thinking' } );
  }

  return (
    <section
      className={[styles.contentcomponentSpacenohost, className].join(" ")}
    >
      <div className={styles.container}>
        <div className={styles.contentwarp}>
          <div className={styles.infomation}>
            <div className={styles.textWrapper}>
              <h1 className={styles.zermattCardTitle}>
                Space for motorhomes but don’t want to host?
              </h1>
              <h3 className={styles.zermattCardDescription}>
                With our all-inclusive management service, you can enjoy the
                benefits of hosting without the day-to-day responsibilities. We
                handle all aspects of running your aire, from guest interactions
                to facility maintenance. Our revenue-sharing model means you
                receive a portion of the hosting fees without lifting a finger.
                This option is perfect for landowners who want to maximise their
                property's potential with minimal time investment. Let our
                expertise work for you, turning your land into a profitable aire
                while you sit back and enjoy the returns.
              </h3>
            </div>
            <div className={styles.cta}>
              <div className={styles.ctaChild} />
              <button className={styles.locobutton} onClick={handleContactClick}>
                <div className={styles.button}>
                  Get in touch to find out more
                </div>
                <img className={styles.icon} alt="" src="/icon2.svg" />
              </button>
            </div>
          </div>
          <div className={styles.graphicwrapper}>
            <div className={styles.illustrations}>
              <img
                className={styles.undrawBusinessDealReUp4uIcon}
                loading="lazy"
                alt=""
                src="/undraw-business-deal-re-up4u-1-1.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContentComponentSpaceNoHost.propTypes = {
  className: PropTypes.string,
};

export default ContentComponentSpaceNoHost;
