import PropTypes from "prop-types";
import styles from "./ContentComponentGettingStarte.module.css";
import { useHistory } from "react-router-dom";

const ContentComponentGettingStarte = ({ className = "" }) => {
  const history=useHistory();

  function handleSignup(event) {
    event.preventDefault();
    history.push("/signup");
  }


  function handleContactClick(event) {
    event.preventDefault();
    history.push("/contact", { from: 'thinking' } );
  }
  return (
    <section
      className={[styles.contentcomponentGettingstarte, className].join(" ")}
    >
      <div className={styles.container}>
        <div className={styles.contentwarp}>
          <div className={styles.graphicwrapper}>
            <img
              className={styles.zermattCardImage}
              loading="lazy"
              alt=""
              src="/zermatt-card-image@2x.png"
            />
          </div>
          <div className={styles.infomation}>
            <div className={styles.textWrapper}>
              <h1 className={styles.zermattCardTitle}>
                Getting started is easy
              </h1>
              <h3 className={styles.zermattCardDescriptionContainer}>
                <p className={styles.readyToBecomeAnAirestopHo}>
                  <span className={styles.readyToBecome}>
                    Ready to become an AireStop host?
                  </span>
                  <span>{` Great! Follow the link below to complete your registration. `}</span>
                </p>
                <p className={styles.blankLine}>&nbsp;</p>
                <p className={styles.stillGotACoupleOfQuestion}>
                  <span
                    className={styles.stillGotA}
                  >{`Still got a couple of questions? `}</span>
                  <span>
                    We’re here to help. Contact our team to discuss your
                    property and hosting goals. We'll guide you through the
                    process, whether you choose to manage your aire yourself or
                    opt for our all-inclusive service. Start your hosting
                    journey today and discover the rewards of being part of the
                    AireStop network.
                  </span>
                </p>
              </h3>
            </div>
            <div className={styles.cta}>
              <div className={styles.ctaChild} />
              <button className={styles.locobutton} onClick={handleContactClick}>
                <div className={styles.button}>Contact us today</div>
              </button>
              <button className={styles.locobutton1} onClick={handleSignup}>
                <div className={styles.button1}>Become a Host</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContentComponentGettingStarte.propTypes = {
  className: PropTypes.string,
};

export default ContentComponentGettingStarte;
