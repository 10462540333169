import PropTypes from "prop-types";
import styles from "./HeroSectionForMotoroamers.module.css";

const HeroSectionForMotoroamers = ({ className = "" }) => {
  return (
    <section
      className={[styles.herosectionFormotoroamers, className].join(" ")}
    >
      <div className={styles.content}>
        <div className={styles.textwrap}>
          <div className={styles.info}>
            <div className={styles.text}>
              <h1 className={styles.simpleAndCleanContainer}>
                <p className={styles.forMotoroamers}>For motoroamers,</p>
                <p className={styles.byMotoroamers}>by motoroamers</p>
              </h1>
              <div className={styles.powerfulYetClean}>
                Maximising the quantity and quality of stopovers by making it
                easy and worthwhile for landowners.
              </div>
            </div>
            <div className={styles.ctaGroup}>
              <div className={styles.locobutton}>
                <div className={styles.button}>Demo</div>
              </div>
              <div className={styles.locobutton1}>
                <div className={styles.button1}>Buy</div>
              </div>
            </div>
          </div>
        </div>
        <img
          className={styles.contentChild}
          loading="lazy"
          alt=""
          src="/rectangle-12@2x.png"
        />
      </div>
    </section>
  );
};

HeroSectionForMotoroamers.propTypes = {
  className: PropTypes.string,
};

export default HeroSectionForMotoroamers;
