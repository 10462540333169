import PropTypes from "prop-types";
import styles from "./Infomation.module.css";

const Infomation = ({
  className = "",
  zermattCardTitle,
  zermattCardDescription,
}) => {
  return (
    <div className={[styles.infomation, className].join(" ")}>
      <div className={styles.textWrapper}>
        <h1 className={styles.zermattCardTitle}>{zermattCardTitle}</h1>
        <div className={styles.zermattCardDescription}>
          {zermattCardDescription}
        </div>
      </div>
      <div className={styles.cta}>
        <div className={styles.ctaChild} />
        <div className={styles.locobutton}>
          <div className={styles.button}>Button</div>
          <img className={styles.icon} alt="" src="/icon2.svg" />
        </div>
      </div>
    </div>
  );
};

Infomation.propTypes = {
  className: PropTypes.string,
  zermattCardTitle: PropTypes.string,
  zermattCardDescription: PropTypes.string,
};

export default Infomation;
