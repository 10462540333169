import PropTypes from "prop-types";
import styles from "./TestimonialSectionGuests.module.css";

const TestimonialSectionGuests = ({ className = "", feedback }) => {
  const images=["/vector.svg","/vector-6.svg","/vector-12.svg","/vector-18.svg"]

  function rendeFeedback() {
    return feedback.map((testamonial, index) => {
      let key = "feedback-"+index;
      let imageId = index%4;
      return (
        <div key={key}>
          <div className={styles.cardtestimonialCard}>
            <div className={styles.graphicGroup}>
              <div className={styles.testimonialImages} />
              <div className={styles.usericons}>
                <img 
                  className={styles.testimonialUserAvatars}
                  loading="lazy"
                  alt=""
                  src={images[imageId]}
                />
              </div>
            </div>
            <div className={styles.theBigOxmox}>
              {testamonial.text}
            </div>
            <div className={styles.vectorParent}>
              <img className={styles.vectorIcon} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon1} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon2} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon3} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon4} alt="" src="/vector-1.svg" />
            </div>
            <div className={styles.testimonialUserInfo}>
              <div className={styles.name}>{testamonial.name}</div>
              <div className={styles.testimonialUserTypes}>
                <div className={styles.guestHost}>{testamonial.type}</div>
              </div>
            </div>
          </div>
        </div>
      );
    })
  }

  return (
    <section className={[styles.testimonialsectionGuests, className].join(" ")}>
      <div className={styles.content}>
        <div className={styles.titletext}>
          <h1 className={styles.whatOurGuests}>What Our Guests Are Saying</h1>
        </div>
        <div className={styles.cardwrap}>
          {rendeFeedback()}
{/*}
          <div className={styles.cardtestimonialCard}>
            <div className={styles.graphicGroup}>
              <div className={styles.testimonialImages} />
              <div className={styles.usericons}>
                <img
                  className={styles.testimonialUserAvatars}
                  loading="lazy"
                  alt=""
                  src="/vector.svg"
                />
              </div>
            </div>
            <div className={styles.theBigOxmox}>
              The booking and payment process was extremely easy. We would like
              to see many more of these aires set up.
            </div>
            <div className={styles.vectorParent}>
              <img className={styles.vectorIcon} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon1} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon2} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon3} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon4} alt="" src="/vector-1.svg" />
            </div>
            <div className={styles.testimonialUserInfo}>
              <div className={styles.name}>Sandra</div>
              <div className={styles.testimonialUserTypes}>
                <div className={styles.guestHost}>Guest</div>
              </div>
            </div>
          </div>


          <div className={styles.cardtestimonialCard1}>
            <div className={styles.graphicGroup1}>
              <div className={styles.graphicGroupChild} />
              <div className={styles.usericons1}>
                <img
                  className={styles.vectorIcon5}
                  alt=""
                  src="/vector-6.svg"
                />
              </div>
            </div>
            <div
              className={styles.theBigOxmox1}
            >{`The ease of the whole process. We turned up, paid using a QR code. It was a lovely location, quiet and felt safe. `}</div>
            <div className={styles.vectorGroup}>
              <img className={styles.vectorIcon6} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon7} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon8} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon9} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon10} alt="" src="/vector-1.svg" />
            </div>
            <div className={styles.nameParent}>
              <div className={styles.name1}>Trudi</div>
              <div className={styles.guestHostWrapper}>
                <div className={styles.guestHost1}>Guest</div>
              </div>
            </div>
          </div>
          <div className={styles.cardtestimonialCard2}>
            <div className={styles.graphicGroup2}>
              <div className={styles.testimonialAvatarsWrapper}>
                <div className={styles.testimonialAvatars} />
              </div>
              <div className={styles.usericons2}>
                <img
                  className={styles.vectorIcon11}
                  alt=""
                  src="/vector-12.svg"
                />
              </div>
            </div>
            <div className={styles.theBigOxmox2}>
              I enjoyed our stay and the online payment system was simple for
              both ourselves and the site owner.
            </div>
            <div className={styles.vectorContainer}>
              <img className={styles.vectorIcon12} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon13} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon14} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon15} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon16} alt="" src="/vector-1.svg" />
            </div>
            <div className={styles.nameGroup}>
              <div className={styles.name2}>Jeff</div>
              <div className={styles.guestHostContainer}>
                <div className={styles.guestHost2}>{`Guest `}</div>
              </div>
            </div>
          </div>
          <div className={styles.cardtestimonialCard3}>
            <div className={styles.graphicGroup3}>
              <div className={styles.usericons3}>
                <img
                  className={styles.vectorIcon17}
                  alt=""
                  src="/vector-18.svg"
                />
              </div>
              <div className={styles.graphicGroupItem} />
            </div>
            <div className={styles.theBigOxmox3}>
              The aire facilities were in perfect working order and spotlessly
              clean. More of the same in the UK please!
            </div>
            <div className={styles.frameDiv}>
              <img className={styles.vectorIcon18} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon19} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon20} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon21} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon22} alt="" src="/vector-1.svg" />
            </div>
            <div className={styles.nameContainer}>
              <div className={styles.name3}>Tim</div>
              <div className={styles.guestHostFrame}>
                <div className={styles.guestHost3}>Guest</div>
              </div>
            </div>
          </div>
          */}
        </div>
      </div>
    </section>
  );
};

TestimonialSectionGuests.propTypes = {
  className: PropTypes.string,
};

export default TestimonialSectionGuests;
