import Infomation from "./Infomation";
import PropTypes from "prop-types";
import styles from "./ContentComponentOurMission.module.css";

const ContentComponentOurMission = ({ className = "" }) => {
  return (
    <section
      className={[styles.contentcomponentOurmission, className].join(" ")}
    >
      <div className={styles.container}>
        <div className={styles.contentwarp}>
          <Infomation
            zermattCardTitle="Our Mission"
            zermattCardDescription="Our mission is to make exploring easier, safer, and more enjoyable for everyone. We believe in empowering motor-adventurers to discover the world around them, from snow-topped mountain peaks and rugged ocean cliffs to local hills and nearby beaches. Our platform connects motorhome and campervan owners with unique stopover locations, helping you build your own adventure, big or small."
          />
          <div className={styles.graphicwrapper}>
            <div className={styles.illustrations}>
              <img
                className={styles.vectorIcon}
                loading="lazy"
                alt=""
                src="/vector1.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContentComponentOurMission.propTypes = {
  className: PropTypes.string,
};

export default ContentComponentOurMission;
