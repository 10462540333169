import React, { useRef, useState, useEffect } from "react";
import { Form, InputGroup, FormGroup, FormControl, FormLabel, Breadcrumb } from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";

import LoaderButtonStandard from "../components/LoaderButtonStandard";
import { onError } from "../libs/errorLib";
import config from "../config";
import "./NewSite.css";
import { API } from "aws-amplify";

import { red, green } from '@material-ui/core/colors';


import { useFormFields } from "../libs/hooksLib";
import { makeStyles, withStyles } from '@material-ui/core/styles';


import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
//import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

//import FileOpenIcon from '@material-ui/icons/FiberManualRecord';
//import Fab from "@material-ui/core/Fab";
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';

//import TextareaAutosize from '@material-ui/core/TextareaAutosize';


import CheckboxFacilities from "../components/CheckboxFacilities";



import RuleInput from "../components/RuleInput"
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';

//import { usePlacesWidget } from "react-google-autocomplete";
import AutoComplete from "react-google-autocomplete";
import Geocode from "react-geocode";

import Dialog from '@material-ui/core/Dialog';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import { GoogleMap, useJsApiLoader, Marker, StandaloneSearchBox, InfoWindow, InfoBox } from '@react-google-maps/api';
import pinIcon from'../images/pin.svg';


import {
  FiCard,
  FiCardActionArea,
  FiCardActions,
  FiCardContent,
  FiCardMedia
} from "../components/ImageCard";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',

    justifyContent: 'space-around',
    flexFlow: 'row wrap',
    alignItems: 'stretch',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  box: {
    flexGrow: 1,
  },
  clear: {height: '30px', width: '30px', cursor:'pointer', float:'right', marginTop: '-14px', marginRight: '-14px', backgroundColor:'#0001', color:'#fff'},
  box1: {
    flexGrow: 1,
  }
}));

export default function NewSite() {
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY2);
  Geocode.setLocationType("ROOFTOP");
  const classes = useStyles();
  const ref = useRef(null);

  const file = useRef(null);
  const history = useHistory();
  const { isCampra } = useAppContext();

  const defaultRules=[
    {key:0, label:"No Fires."},
    {key:1, label:"No awnings or camping furniture."},
    {key:2, label:"Vehicles  must be park within an allotted bay."},
    {key:3, label:"Treat other guests with respect."},
    {key:4, label:"Keep noise to a minimum. no music after 9pm."},
    {key:5, label:"Leave no trace."}
  ];
  const [isLoading, setIsLoading] = useState(false);
  //const [siteOpen, setSiteOpen] = useState(true);
  const [base64Image, setBase64Image] = useState("");
  const [address, setAddress] = useState("");
  const [tempAddress, setTempAddress] = useState("");
  const [location, setLocation] = useState(null);
  const [mapLink, setMapLink] = useState("");
  const [map, setMap] = useState(null);
  const [mapOpen, setMapOpen] = useState(false);
  const [changestate, setChangestate] = useState(false);
  const [isPricingOvernight, setIsPricingOvernight] = useState(false);
  const [isPricingFacilities, setIsPricingFacilities] = useState(false);
  const [newRule, setNewRule] = useState("");
  const [ruleData, setRuleData] = React.useState([]);
  const [priceStay, setPriceStay] = useState("");
  const [priceFacilities, setPriceFacilities] = useState("");
  const [bays, setBays] = useState("");

  const [confirmAddress, setConfirmAddress] = useState(false);
  const [confirmedAddress, setConfirmedAddress] = useState("");


  const [ratio, setRatio] = useState(1.0);
  const [instanceKey, setInstanceKey] = useState(0); // for ruleinput component
  const [fields, handleFieldChange] = useFormFields({
    siteName: "",
    priceFacilities: "",
    priceStay: "",
    bays: "",
    desc: "",
    rules: [],
    facilities: {
      facONS: false,
      facBWD: false,
      facGWD: false,
      facEHU: false,
      facLND: false,
      facREC: false,
      facRUB: false,
      facSWR: false,
      facTLT: false,
      facWTR: false,
      facWFI: false,
    },
    extraDetails: "",
    wifiNetwork: "",
    wifiCode: "",
    address: "",
    mapLink: "",
    location: {},
  });

  const eventhandler = state => {
    fields.facilities.facBWD=state.facBWD;
    fields.facilities.facGWD=state.facGWD;
    fields.facilities.facEHU=state.facEHU;
    fields.facilities.facLND=state.facLND;
    fields.facilities.facREC=state.facREC;
    fields.facilities.facRUB=state.facRUB;
    fields.facilities.facSWR=state.facSWR;
    fields.facilities.facTLT=state.facTLT;
    fields.facilities.facWTR=state.facWTR;
    fields.facilities.facWFI=state.facWFI;
    if (!fields.facilities.facWFI) {
      fields.wifiNetwork = "";
      fields.wifiCode = "";
    }
    setChangestate(!changestate);
  }

const OpenClosedSwitch = withStyles({
  switchBase: {
    color: red[700],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

//const handleSiteOpenChange = (event) => {
//  setSiteOpen(event.target.checked);
//}

//const { isLoaded } = useJsApiLoader({
//  googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
//})



useEffect(() => {
  if (isCampra) {history.push("/")}
  window.scrollTo(0, 0);
  setRuleData(defaultRules);

  //setTimeout(() => {
  //      setMapOpen(true);
  //    }, 1000);

}, [])



function validateForm() {
  if(address!==""){
    fields.address=address;
    //fields.mapLink=mapLink;
    fields.location=location;
  } else {
    fields.address=""
    return false;
  }

  fields.rules=[];
  ruleData.map((data) => {
    fields.rules.push(data.label)
  })

  fields.facilities.facONS = isPricingOvernight;
  fields.priceFacilities=priceFacilities;
  fields.priceStay=priceStay;

  const hasAtLeastOneFacility=(isPricingOvernight || isPricingFacilities) && ((isPricingOvernight && fields.facilities.facONS) || !isPricingOvernight)  && ((isPricingFacilities && (fields.facilities.facBWD || fields.facilities.facGWD || fields.facilities.facEHU || fields.facilities.facLND || fields.facilities.facREC || fields.facilities.facRUB || fields.facilities.facSWR || fields.facilities.facTLT || fields.facilities.facWTR || fields.facilities.facWFI) || !isPricingFacilities));
  const hasWifiCode = !fields.facilities.facWFI || (fields.facilities.facWFI && fields.wifiCode.trim().length>0 && fields.wifiNetwork.trim().length>0);
  const hasRequired = hasWifiCode && hasAtLeastOneFacility && fields.siteName.trim().length > 0  && ((isPricingFacilities && fields.priceFacilities > 0 && (fields.priceFacilities==0 || fields.priceFacilities>=3)) || !isPricingFacilities) && ((isPricingOvernight && fields.priceStay.length > 0 && (fields.priceStay==0 || fields.priceStay>=3)) || !isPricingOvernight) && fields.bays.length > 0  && fields.bays > 0 && fields.desc.length > 0 && fields.rules.length > 0;
  return(hasRequired);
}

function handleFileChange(event) {
  file.current = event.target.files[0];
}
function handleConfirmAddress(event) {
  if (address.trim().length !== 0) {
    setConfirmAddress(false);
  }
}
function handleCancelAddress(event) {
  //if (address.trim().length !== 0) {
    setConfirmAddress(false);
  //}
}
function handleConfirmedAddress(event) {
  setAddress(event.target.value);
}


function handleBaysChange(event) {
  const val = event.target.value;
  if (!isNaN(val)) {
    if (val.indexOf(".")>-1 || val.indexOf("0")===0) { //integer only
      return;
    }
    fields.bays = val;
    setBays(fields.bays);
  }
}

function handlePriceStayChange(event) {
  const val = event.target.value;
  if (!isNaN(val)) {
    if (val.indexOf(".")>-1) {
      let parts = val.split(".");
      if (parts[1].length > 2) {
        return;
      }
      if (val.indexOf("0")===0 && val.length>1 && val.indexOf(".")!==1) {
        return;
      }
    } else if (val.indexOf("0")===0 && val.length>1) {
      return;
    }
    fields.priceStay = val;
    setPriceStay(fields.priceStay);
  }
}

function handlePriceStayOnBlur(event) {
  const val = event.target.value;
  if (val.indexOf(".")>-1) {
    let parts = val.split(".");
    if (parts[1].length === 2) {
      return;
    } else if (parts[1].length === 1) {
      fields.priceStay = val + "0";
    } else if (parts[1].length === 0) {
      fields.priceStay = val + "00";
    }
  } else {
    if (val.length === 0) {
      fields.priceStay = val + "0.00";
    } else {
      fields.priceStay = val + ".00";
    }
  }
  setPriceStay(fields.priceStay);
}


function handlePriceFacilitiesChange(event) {
  const val = event.target.value;
  if (!isNaN(val)) {
    if (val.indexOf(".")>-1) {
      let parts = val.split(".");
      if (parts[1].length > 2) {
        return;
      }
      if (val.indexOf("0")===0 && val.length>1 && val.indexOf(".")!==1) {
        return;
      }
    } else if (val.indexOf("0")===0 && val.length>1) {
      return;
    }
    fields.priceFacilities = val;
    setPriceFacilities(fields.priceFacilities);
  }
}

function handlePriceFacilitiesOnBlur(event) {
  const val = event.target.value;
  if (val.indexOf(".")>-1) {
    let parts = val.split(".");
    if (parts[1].length === 2) {
      return;
    } else if (parts[1].length === 1) {
      fields.priceFacilities = val + "0";
    } else if (parts[1].length === 0) {
      fields.priceFacilities = val + "00";
    }
  } else {
    if (val.length === 0) {
      fields.priceFacilities = val + "0.00";
    } else {
      fields.priceFacilities = val + ".00";
    }
  }
  setPriceFacilities(fields.priceFacilities);
}

const handleChangeAddress = (e) => {
  setTempAddress(e.target.value);
}


function getReducedImageAsBase64(file) {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(null);
    };
    reader.onload = () => {
      var img = new Image();
      img.onload = function() {
        setRatio(img.height/img.width);
        let h = 600;
        let w = 600;
        if (img.height > img.width) {
          w = w * (img.width / img.height);
        } else {
          h = h * (img.height / img.width);
        }
        var oc = document.createElement('canvas');
        oc.width = w;
        oc.height = h;
        var ctx = oc.getContext('2d');
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, w, h);
        ctx.drawImage(img, 0, 0, w, h);
        var dataurl = oc.toDataURL('image/jpeg'); //context.file.type
        resolve(dataurl);
      }
      img.src = reader.result
    };
    reader.readAsDataURL(file);
  });
}
async function handleUploadClick(event) {
  if (event.target.files.length == 0) {
    return;
  }
  var file = event.target.files[0];
  const reader = new FileReader();
  var url = reader.readAsDataURL(file);

  if (file && file.type.indexOf("image") == -1) {
    file = null;
} else {
  if (file) {
    const base64 = await getReducedImageAsBase64(file);
    setBase64Image("" + base64);
  }
}
};

function handleClearPic() {
  setBase64Image("");
}




function handlePricingFacilitiesCheck(event) {
  if (!event.target.checked) {
    fields.priceFacilities="";
    setPriceFacilities(fields.priceFacilities);
    if (!isPricingOvernight) {
      //clearFacilities();
    }
  }
  setIsPricingFacilities(event.target.checked);
}
function handlePricingOvernightCheck(event) {
  if (!event.target.checked) {
    fields.priceStay="";
    setPriceStay(fields.priceStay);
    if (!isPricingFacilities) {
      //clearFacilities();
    }
  }
  fields.facilities.facONS = event.target.checked;
  setIsPricingOvernight(event.target.checked);
}


function handleAddRuleClick() {
  fields.rules.unshift(newRule);
  ruleData.splice(0,ruleData.length)
  fields.rules.map((rule, index) => {
    ruleData.push({ key: index, label: rule });
  })
  setInstanceKey(i => i + 1);
  setNewRule("");
}
const newRuleHandler = data => {setNewRule(data.rule) }


React.useEffect(() => {
  fields.rules=[];
  ruleData.map((data) => {
    fields.rules.push(data.label)
  })
}, [ruleData]);

const handleDeleteRule = (ruleToDelete) => () => {
  setRuleData((rules) => rules.filter((rule) => rule.key !== ruleToDelete.key));
};



async function handleSubmit(event) {
  event.preventDefault();

  /*
  if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
  alert(
  `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
  1000000} MB.`
);
return;
}
*/

setIsLoading(true);

try {
  //const result = await createSite({ content: fields, image: base64Image, itemStatus: siteOpen?1:0});
  const result = await createSite({ content: fields, image: base64Image, itemStatus: 1}); // itemStatus -> DEfault = 1 (i.e. Open)
  history.push("/sites");
} catch (e) {
  onError(e);
  setIsLoading(false);
}
}

function createSite(site) {
  return API.post("aires", "/sites", {
    body: site
  });
}


async function onMarkerDragEnd(t) {
  if (t && t.latLng) {
    setLocation({"lat": t.latLng.lat(), "lng": t.latLng.lng()});
    Geocode.fromLatLng(t.latLng.lat(),  t.latLng.lng()).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const url = response.results[0].url;
        setAddress(address);
        setMapLink(url);
      },
      (error) => {
        // popup a dialog asking for the address / or confirming the address
        // please confirm the physical address you wish to associate with this location
        setAddress("");
        setMapLink("");
        setConfirmAddress(true);
        //console.error(error);
      }
    );
  }
}

function openMapClick(e) {
  e.preventDefault();
  setMapOpen(!mapOpen);
}
const containerStyle = {
  width: '100%',
  height: "300px"
};
const onMapLoad = React.useCallback(function callback(map) {
  //map.fitBounds(UK_BOUNDS);
  setMap(map)
}, [])

const onUnmount = React.useCallback(function callback(map) {
  setMap(null)
}, [])

const onMapClick = (...args) => {
  //setInfoOpen(false);
}
const UK_BOUNDS = {
  south: 49.79129622491783,
  west: -10.795963172325314,
  north: 58.84452996184098,
  east: 2.1239587026746864
};

const RESTRICT_BOUNDS = {south: 10.994892342824796, west: -39.88202649919864, north: 74.86496038005808, east: 27.61797350080136};

const mapOptions = {
  restriction: {
    latLngBounds: RESTRICT_BOUNDS,
  },
  mapTypeControl: true,
  streetViewControl: false,
};





function addressClickHandler(e) {
  e.preventDefault();
}

handleFileChange = async event => {
  event.preventDefault();
  this.file = event.target.files[0];
  if (this.file && this.file.type.indexOf("image") == -1) {
    this.file = null;
    this.inputEl.value='';
    this.setState({
      alertOpen: true,
      alertTitle: 'Invalid Image Format',
      alertMessage: 'Please choose a valid image for the employee pic.'
    });
  } else {
    if (this.file) {
      const base64 = await this.getReducedImageAsBase64(this);
      this.setState({ binaryImage: base64});
    }
  }

}

return (
  <>
  <div  className="lander">


  <Form onSubmit={handleSubmit} >
  <Box  paddingLeft={'12px'} paddingTop={0} paddingRight={'12px'} paddingBottom={0}>
  <h3>Create Your AireStop</h3>

  <Paper variant="outlined">
  <Box  p={1}>
  <a><b>*Site Name</b><br/>The name of your AireStop.</a>
  </Box>
  <Box display="flex" p={1} padding={0} >
  <Box p={1} flexGrow={1} >
  <TextField id="siteName"
  value={fields.siteName}
  onChange={handleFieldChange}
  name="siteName"
  inputProps={{style: {textTransform: 'none'}}}
  fullWidth
  label="*Site Name" type="text" variant="outlined" size="small"/>
  </Box>
  {/*
  <Box p={1} >
  <FormControlLabel
  style={{marginRight:"4px"}}
  control={
    <OpenClosedSwitch id='siteOpen'
    checked={siteOpen}
    onChange={handleSiteOpenChange}
    name="siteOpen"
    color="secondary"
    />
  }
  label={ siteOpen?"Open":"Closed"}
  />
  </Box>
  */}
  </Box>
  </Paper>





  <br/>





  <Paper variant="outlined">
  <Box  p={1}>
  <a><b>*Address</b><br/>Enter the address (or a nearby address) of your AireStop.<br/>Use the below map to indicate your sites exact location if necessary.</a><br/>
  {/*Alternatively you can enter a <a href={"https://plus.codes/"} target="_blank">Google Maps Plus Code</a> here.*/}
  </Box>
  <Box  p={1}>







  <AutoComplete
  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
  onPlaceSelected = {(place) => {
    if (place && place.geometry && place.geometry.location) {
      setLocation({"lat": place.geometry.location.lat(), "lng": place.geometry.location.lng()});
    }
    setAddress(place.formatted_address);
    setMapLink(place.url);
    setTempAddress("");
  }}
  style={{
    width:"95%",
    color: "#222",
    borderRadius: '5px',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '8px 8px 8px 8px',
  }}
  options = {{
    types: [] ,//'address'
    componentRestrictions:  { country: "gb" } ,
    fields: ["formatted_address", "address_components", "url", "geometry"],
    //inputAutocompleteValue: "formatted_address",
  }}
  value={tempAddress}
  onChange={handleChangeAddress}
  />
  </Box>
  <Box  px={2} py={address===""?0:1}>
  <a href={location?"https://google.com/maps/place/" + location.lat + "," + location.lng:mapLink} target="_blank">{address}</a>
  </Box>
  <Box  p={1}>
  <a>If your address is unable to be found please use the map below to indicate your exact location.</a>
  </Box>
  <Box  p={1}>
  {<Button component="span" color="primary" disabled={false} onClick={openMapClick} variant="contained" endIcon={mapOpen?<KeyboardArrowUpRoundedIcon />:<KeyboardArrowDownRoundedIcon />}>
  {mapOpen?"Hide Map":"Show Map"}
  </Button>}
  {/*mapOpen && location?*/}
  {mapOpen &&

    <Box py={0.2}>
    Drag pin to the exact location of your Airestop.
    <Paper variant="outlined">
    <GoogleMap
    mapContainerStyle={containerStyle}
    zoom={location?14:5}
    center={location?location:{lat:54.237933,lng:-2.36967}}
    onLoad={onMapLoad}
    onUnmount={onUnmount}
    onClick={onMapClick}
    options = {mapOptions}
    >
    <Marker icon={{url: pinIcon, size: {width: 40, height: 40}, origin: {x: 0, y: 0}, anchor: {x: 20, y: 35}, scaledSize: {width: 40, height: 40}}} position={location?location:{lat:54.237933,lng:-2.36967}} title={fields.siteName} draggable={true} onDragEnd={(t, map, coord) => onMarkerDragEnd(t)}/>
    </GoogleMap>
    </Paper>
    </Box>



  }

  </Box>


  {/*
  <Box p={1}>
  <TextField
  id="extraDirections"
  name="extraDirections"
  value={fields.extraDirections}
  onChange={handleFieldChange}
  label="Extra directions"
  multiline
  fullWidth
  size="small"
  variant="outlined"
  inputProps={{ maxLength: 1000 }}
  />
  <Box  px={1}>
  <small>eg: The turning is just after the Texaco garage.</small>
  </Box>
  </Box>
*/}


  </Paper>




  <br/>






  <FiCard ref={ref} className={classes.card} variant="outlined" >
  <FiCardMedia
  media="picture"
  alt="AireStop"
  image={base64Image}
  title="AireStop"
  />
  <FiCardContent className={classes.fiCardContent} >
  {base64Image?
    <ClearRoundedIcon className={classes.clear} onClick={handleClearPic} />
    :
    <Box  mt={-1} ml={-1}>
    <a><b>AireStop Photo</b><br/>We recommend a <b><i>landscape</i></b> oriented photo of your Aire.</a><br/><br/>
    </Box>
  }
  <Box  display="flex" p={0} m={0} style={{height:ratio*(base64Image && ref.current ? ref.current.offsetWidth : 0)}}>
    <label htmlFor="upload-photo">
      <input
        accept="image/*"
        className={classes.input}
        id="upload-photo"
        name="upload-photo"
        //multiple
        type="file"
        onChange={handleUploadClick}
        hidden
        />
        <Button color="primary" variant="contained" component="span" startIcon={base64Image?<InsertPhotoIcon/>:<AddPhotoAlternateIcon/>}>
          {base64Image?"Change Photo":"Add Photo"}
        </Button>
  </label>
  </Box>
  <br/><br/>
  </FiCardContent>
  </FiCard>
  <br/>
  <Paper variant="outlined" >
  <Box  padding={1}>
  <a><b>*Pricing</b><br/>Using the tick box, select the options you offer. Then, using the text box, enter how much you would like to charge your Guests.<br/>If the service is free enter £0, otherwise enter a value greater or equal to £3.00.</a>

  </Box>

  <Box display="flex" flexDirection="column" p={1} padding={1} >
  <Box p={1}  display="flex"  flexGrow={1} paddingLeft={0} paddingTop={0} paddingRight={0} paddingBottom={0}>
  <Checkbox checked={isPricingOvernight} onChange={handlePricingOvernightCheck}  name="checkOvernight" color="secondary"/>
  <TextField id="priceStay"
  disabled={!isPricingOvernight}
  value={priceStay}
  onChange={handlePriceStayChange}
  onBlur={handlePriceStayOnBlur}
  name="priceStay"
  InputProps={{
    startAdornment:
    <>£</>
  }}

  fullWidth
  label="Stay Overnight (includes facilities)" type="text" variant="outlined" size="small"/>
  </Box>
  <br/>
  <Box p={1} display="flex" flexGrow={1} margin={0} paddingLeft={0} paddingTop={0} paddingRight={0} paddingBottom={0}>
  <Checkbox checked={isPricingFacilities} onChange={handlePricingFacilitiesCheck} name="checkFacilities" color="secondary" padding="0px"/>
  <TextField id="priceFacilities"
  disabled={!isPricingFacilities}
  value={priceFacilities}
  startAdornment={<InputAdornment position="start">£</InputAdornment>}
  onChange={handlePriceFacilitiesChange}
  onBlur={handlePriceFacilitiesOnBlur}
  name="priceFacilities"
  fullWidth
  InputProps={{
    startAdornment:
    <>£</>
  }}
  label="Use Facilities Only"  type="text"  variant="outlined" size="small"/>


  </Box>

  </Box>

  </Paper>
  <br/>
  <Paper variant="outlined" padding={1}>
  <Box  padding={1}>
  <a><b>*Facilities</b></a>
  </Box>
  <Box  padding={1}>

  <CheckboxFacilities id="facilitiesid"
  onChange={eventhandler}
  disabled={!(isPricingOvernight || isPricingFacilities)}
  values={fields.facilities}/>




  <Box display="inline-block" flexDirection="column" >
  <Box display="inline-block">
  <Paper  variant="outlined" >
  <Box mx={1} mt={1} >
  <a>*<u>Wifi Details</u></a>
  </Box>
  <Box p={1} display="inline-block"  maxWidth="170px">

  <TextField id="wifiNetwork"
  value={fields.wifiNetwork}
  disabled={!fields.facilities.facWFI}
  onChange={handleFieldChange}
  name="wifiNetwork"
  label="Network Name"  variant="outlined" size="small"/>
  </Box>
  <Box p={1} display="inline-block"  maxWidth="170px">
  <TextField id="wifiCode"
  value={fields.wifiCode}
  disabled={!fields.facilities.facWFI}
  onChange={handleFieldChange}
  name="wifiCode"
  label="Password"  variant="outlined" size="small"/>
  </Box>
  </Paper>
  </Box>

  <br/><br/>

          <Box display="inline-block">
          <Paper  variant="outlined" >
          <Box mx={1} mt={1} >
          <a><u>Extra Details</u><br/>These are extra details that will only be made available (as with the Wifi details) to a payed-up customer. Eg: Facilities access codes etc.</a>
          </Box>
          <Box p={1} display="flex" width="100%">
          <TextField id="extraDetails"
          multiline
          fullWidth
          value={fields.extraDetails}
          disabled={false}
          onChange={handleFieldChange}
          name="extraDetails"
          label="Extra Details"  variant="outlined" size="small"/>
          </Box>
          </Paper>
          </Box>





  <br/><br/>
  <TextField id="bays"
  value={bays}
  onChange={handleBaysChange}
  name="bays"
  label="*Number of Bays" type="text" variant="outlined" size="small"/>



  </Box>
  </Box>
  </Paper>

  <br/>
  <Paper variant="outlined">
  <Box  p={1}>
  <a><b>*Description</b><br/>Use this space to welcome your guests, tell them a bit about your AireStop, and let them know about things they can do locally.</a>
  </Box>
  <Box  p={1}>


  <TextField
  id="desc"
  name="desc"
  value={fields.desc}
  onChange={handleFieldChange}
  label="Enter description"
  placeholder="Example: Welcome to our Aire beside the big hill. We hope you have a great stay and enjoy the beautiful views on offer. Fresh water can be accessed at the tap by the main gate and Electrical hook-up is available in bays 1-3. Our nearest village, littletown, is just over a mile away and has a village shop and local pub. The pub does a great Sunday roast (booking ahead is strongly recommended to avoid disappointment) and there is a farmers market in the village square every Saturday morning from 10am."
  multiline
  fullWidth
  size="small"
  variant="outlined"
  inputProps={{ maxLength: 1000 }}
  />

  </Box>
  </Paper>
  <br/>

  <Paper variant="outlined">
  <Box  p={1}>
  <a><b>*Rules</b><br/>Use this space to inform your guests of any rules you would like them to abide by.<br/>Once you’ve added a rule hit the + on the right to add another. To remove a rule click the x on the rule.</a>
  </Box>
  <Box px={1}>
  <RuleInput id="ruleid"  key={instanceKey}
  onChange={newRuleHandler}
  size="small"
  onClick={() => { handleAddRuleClick() }}
  />
  </Box>
  <Box  p={1}
  flexDirection="column">
  {ruleData.map((data) => {
    return (

      <Chip key={data.key}
      variant="outlined"
      size="small"
      color="primary"
      style={{height:"100%",margin: '8px'}}
      label={<Typography style={{whiteSpace: 'normal'}}>{data.label}</Typography>}
      onDelete={handleDeleteRule(data)}
      />

    );
  })}
  </Box>
  </Paper>
  <br/>
  <br/>



























  <Box  padding={0}>


  <LoaderButtonStandard
  fullWidth
  type="submit"
  size="lg"
  variant="primary"
  isLoading={isLoading}
  disabled={!validateForm()}
  >
  Create Site
  </LoaderButtonStandard>

  </Box>

  <br/>

  </Box>

  </Form>

  </div>
  <Dialog open={confirmAddress}>
  <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >{"Confirm Address"}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-slide-description">
      An address was not found at this location, please enter the address you wish to associate with this location.
    </DialogContentText>

    <TextField id="address"
    fullWidth
    value={address}
    onChange={handleConfirmedAddress}
    name="address"
    placeholder="Type in your adddress"
    label="Address" type="text" variant="outlined" size="small"/>

  </DialogContent>
  <DialogActions>
  <Button onClick={handleCancelAddress} color="primary" >
    Cancel
  </Button>
  <Button onClick={handleConfirmAddress} color="primary" >
    Save
  </Button>
  </DialogActions>
</Dialog>
</>
);
}
