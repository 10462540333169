import CardTestimonialCard from "./CardTestimonialCard";
import PropTypes from "prop-types";
import styles from "./TestimonialSectionHosts.module.css";

const TestimonialSectionHosts = ({ className = "", feedback }) => {
  console.log(feedback);




  function rendeFeedback() {
    return feedback.map((testamonial, index) => {
      let key = "feedback-"+index;
      return (
        <CardTestimonialCard key={key}
        undrawMaleAvatarG98d1={testamonial.orientation==="m"?"/undraw-male-avatar-g98d-1.svg":"/undraw-female-avatar-efig-1.svg"}
        theBigOxmoxAdvisedHerNotT={testamonial.text}
        name1={testamonial.name}
      />
      );
    })
  }


  return (
    <section className={[styles.testimonialsectionHosts, className].join(" ")}>
      <div className={styles.content}>
        <div className={styles.titletext}>
          <h1 className={styles.whatOurGuests}>What Our Hosts Are Saying</h1>
        </div>
        <div className={styles.cardwrap}>
          {rendeFeedback()}
        <div className={styles.cardtestimonialCard}>
            <div className={styles.graphicGroup}>
              <div className={styles.graphicGroupChild} />
              <div className={styles.usericons}>
                <img className={styles.vectorIcon} alt="" src="/vector.svg" />
              </div>
            </div>
            <div className={styles.theBigOxmox}>
              The Big Oxmox advised her not to do so, because there were
              thousands of bad Commas
            </div>
            <div className={styles.vectorParent}>
              <img className={styles.vectorIcon1} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon2} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon3} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon4} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon5} alt="" src="/vector-1.svg" />
            </div>
            <div className={styles.nameParent}>
              <div className={styles.name}>Name</div>
              <div className={styles.guestHost}>Guest / Host</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

TestimonialSectionHosts.propTypes = {
  className: PropTypes.string,
};

export default TestimonialSectionHosts;
