
import React, { useEffect} from "react";
//import { useNavigate } from "react-router-dom";
//import TopHeaderLrg1 from "../components/TopHeaderLrg1";
import HeroSectionThinkingofBecoming from "../components/HeroSectionThinkingofBecoming";
import ContentComponentUnlockThePote from "../components/ContentComponentUnlockThePote";
import ContentComponentHowAireStopSu from "../components/ContentComponentHowAireStopSu";
import ContentComponentHowComeItsFre from "../components/ContentComponentHowComeItsFre";
import ContentComponentGettingStarte from "../components/ContentComponentGettingStarte";
import ContentComponentSpaceNoHost from "../components/ContentComponentSpaceNoHost";
import FooterPublicLrg from "../components/FooterPublicLrg";
import styles from "./LocoThinkingAboutHosting.module.css";

const LocoThinkingAboutHosting = () => {
  //const navigate = useNavigate();

  //const onAboutUsTextClick = useCallback(() => {
  //  navigate("/locoabout11");
  //}, [navigate]);

  useEffect(() => {

    async function onLoad() {
    }
    window.scrollTo(0, 0);
    onLoad();
  }, []);

  return (
    <div className={styles.locothinkingabouthosting11}>
      <HeroSectionThinkingofBecoming />
      <ContentComponentUnlockThePote />
      <ContentComponentHowAireStopSu />
      <ContentComponentHowComeItsFre />
      <ContentComponentGettingStarte />
      <ContentComponentSpaceNoHost />
      <FooterPublicLrg/>
    </div>
  );
};

export default LocoThinkingAboutHosting;
