const config = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://b8l2i57r2m.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_oGo5nONiL",
    APP_CLIENT_ID: "6l82585k4sic53ekkkv1vm7val",
    IDENTITY_POOL_ID: "us-east-1:99f8c915-eaf1-45e6-9152-42bfe0ebe5ee",
  },
};

export default config;
