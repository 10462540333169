import TextWrap from "./TextWrap";
import PropTypes from "prop-types";
import styles from "./HeroSectionThinkingofBecoming.module.css";

const HeroSectionThinkingofBecoming = ({ className = "" }) => {

  
  return (
    <section
      className={[styles.herosectionThinkingofbecoming, className].join(" ")}
    >
      <div className={styles.content}>
        <TextWrap
          simpleAndCleanUIs="Thinking of becoming a host?"
          propPadding="147px 0px"
        />
        <img
          className={styles.backgroundShapeIcon}
          loading="lazy"
          alt=""
          src="/rectangle-13@2x.png"
        />
      </div>
    </section>
  );
};

HeroSectionThinkingofBecoming.propTypes = {
  className: PropTypes.string,
};

export default HeroSectionThinkingofBecoming;
