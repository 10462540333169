import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
//import { useCallback } from "react";
//import { useNavigate } from "react-router-dom";
//import TopHeaderLrg1 from "../components/TopHeaderLrg1";
import HeroSectionHostingMadeSimple from "../components/HeroSectionHostingMadeSimple";
import ContentComponentThinkingOfHos from "../components/ContentComponentThinkingOfHos";
import ContentComponentOurServices from "../components/ContentComponentOurServices";
import TestimonialSectionHosts from "../components/TestimonialSectionHosts";
import FooterPublicLrg from "../components/FooterPublicLrg";
import styles from "./LocoHomeHost.module.css";

const LocoHomeHost = () => {
  const [feedback, setFeedback] = useState([]);

  /*
  const navigate = useNavigate();

  const onAboutUsTextClick = useCallback(() => {
    navigate("/locoabout11");
  }, [navigate]);
*/


useEffect(() => {

  async function onLoad() {
      const result = await getConfig();
      setFeedback(result.feedback);
  }
  window.scrollTo(0, 0);
  onLoad();
}, []);



function getConfig() {
  return API.get("aires", `/sites/FEEDBACK/CONFIG`);
  //return API.get("aires", `/sitesadmin?isconfig=1`);
}



  return (
    <div className={styles.locohomehost11}>
      <HeroSectionHostingMadeSimple />
      <ContentComponentThinkingOfHos />
      <ContentComponentOurServices />
      <TestimonialSectionHosts feedback={feedback}/>
      <FooterPublicLrg />
    </div>
  );
};

export default LocoHomeHost;

