import Infomation from "./Infomation";
import PropTypes from "prop-types";
import styles from "./ContentComponentConnectingMot.module.css";

const ContentComponentConnectingMot = ({ className = "" }) => {
  return (
    <section
      className={[styles.contentcomponentConnectingmot, className].join(" ")}
    >
      <div className={styles.container}>
        <div className={styles.contentwarp}>
          <div className={styles.graphicwrapper}>
            <div className={styles.illustrations}>
              <img
                className={styles.undrawOnlineConnection6778Icon}
                loading="lazy"
                alt=""
                src="/undraw-online-connection-6778-1.svg"
              />
            </div>
          </div>
          <Infomation
            zermattCardTitle="Connecting Motoroamers and Landowners"
            zermattCardDescription="Our innovative platform bridges the gap between motoroamers seeking convenient parking and facilities, and landowners with available space. We provide a user-friendly interface where guests can easily find, book, and pay for services such as overnight parking, water replenishment, and waste disposal. Our network of aires offers a diverse range of locations, each with its own unique profile detailing available facilities and host rules."
          />
        </div>
      </div>
    </section>
  );
};

ContentComponentConnectingMot.propTypes = {
  className: PropTypes.string,
};

export default ContentComponentConnectingMot;
