import TextWrap from "./TextWrap";
import PropTypes from "prop-types";
import styles from "./HeroSectionHostingMadeSimple.module.css";

const HeroSectionHostingMadeSimple = ({ className = "" }) => {
  return (
    <section
      className={[styles.herosectionHostingmadesimple, className].join(" ")}
    >
      <div className={styles.content}>
        <img
          className={styles.backgroundIcon}
          loading="lazy"
          alt=""
          src="/rectangle-11@2x.png"
        />
        <TextWrap simpleAndCleanUIs="Aire Hosting Made Simple. " />
      </div>
    </section>
  );
};

HeroSectionHostingMadeSimple.propTypes = {
  className: PropTypes.string,
};

export default HeroSectionHostingMadeSimple;
