import CardSitePreviewSmall from "./CardSitePreviewSmall";
import PropTypes from "prop-types";
import styles from "./FeatureSectionFeaturedSites.module.css";

const FeatureSectionFeaturedSites = ({ className = "", featuredSites, onSiteIdClick }) => {


  function renderFeaturedSites() {
    return featuredSites.map((site, index) => {
      let key = "fs-"+index;
      return (
        <CardSitePreviewSmall  key={key} site={site} onSiteIdClick={onSiteIdClick}/>
      );
    })
  }
  return (
    <section
      className={[styles.featuresectionFeaturedsites, className].join(" ")}
    >
      <div className={styles.content}>
        <div className={styles.titletext}>
          <h1 className={styles.featuredStops}>Featured Stops</h1>
        </div>
        <div className={styles.cardwrap}>
          {renderFeaturedSites()}
        </div>
      </div>
    </section>
  );
};

FeatureSectionFeaturedSites.propTypes = {
  className: PropTypes.string,
};

export default FeatureSectionFeaturedSites;
