import PropTypes from "prop-types";
import styles from "./CardTestimonialCard.module.css";

const CardTestimonialCard = ({
  className = "",
  undrawMaleAvatarG98d1,
  theBigOxmoxAdvisedHerNotT,
  name1,
}) => {
  return (
    <div className={[styles.cardtestimonialCard, className].join(" ")}>
      <div className={styles.graphicGroup}>
        <div className={styles.userCircle} />
        <div className={styles.usericons}>
          <img
            className={styles.undrawMaleAvatarG98d1Icon}
            loading="lazy"
            alt=""
            src={undrawMaleAvatarG98d1}
          />
        </div>
      </div>
      <div className={styles.theBigOxmox}>{theBigOxmoxAdvisedHerNotT}</div>
      <div className={styles.vectorParent}>
        <img className={styles.vectorIcon} alt="" src="/vector-1.svg" />
        <img className={styles.vectorIcon1} alt="" src="/vector-1.svg" />
        <img className={styles.vectorIcon2} alt="" src="/vector-1.svg" />
        <img className={styles.vectorIcon3} alt="" src="/vector-1.svg" />
        <img className={styles.vectorIcon4} alt="" src="/vector-1.svg" />
      </div>
      <div className={styles.nameParent}>
        <div className={styles.name}>{name1}</div>
        <div className={styles.userType}>
          <div className={styles.guestHost}>Host</div>
        </div>
      </div>
    </div>
  );
};

CardTestimonialCard.propTypes = {
  className: PropTypes.string,
  undrawMaleAvatarG98d1: PropTypes.string,
  theBigOxmoxAdvisedHerNotT: PropTypes.string,
  name1: PropTypes.string,
};

export default CardTestimonialCard;
