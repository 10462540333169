import PropTypes from "prop-types";
import styles from "./Card.module.css";

const Card = ({
  className = "",
  solidIcon,
  title,
  locateAnAireUsingYourPref,
}) => {
  return (
    <div className={[styles.card, className].join(" ")}>
      <img className={styles.solidIcon} loading="lazy" alt="" src={solidIcon} />
      <div className={styles.title}>{title}</div>
      <div className={styles.locateAnAire}>{locateAnAireUsingYourPref}</div>
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  solidIcon: PropTypes.string,
  title: PropTypes.string,
  locateAnAireUsingYourPref: PropTypes.string,
};

export default Card;
