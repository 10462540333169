import React, { useState, useEffect } from "react";
import { useHistory} from "react-router-dom";
import { onError } from "../libs/errorLib";

import { Auth, API } from "aws-amplify";
import "./Shop.css";

import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';



import Table from "react-bootstrap/Table";

import { makeStyles, withStyles, styled } from '@material-ui/core/styles';


import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Button from '@material-ui/core/Button';
//import Button from "../components/LoaderButtonX";
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

import ButtonBase from '@material-ui/core/ButtonBase';


import Fab from '@material-ui/core/Fab';


import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

//import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
//import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
//import Brightness3Icon from '@material-ui/icons/Brightness3';
//import InvertColorsIcon from '@material-ui/icons/InvertColors';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';

import iTopOvernightOff from'../images/preview/Pay Per Night - Grey.svg';
import iTopBaysOff from'../images/preview/Parking - Grey.svg';
import iTopFacilitiesOff from'../images/preview/Facilities Only - Grey.svg';

import iTopOvernight from'../images/preview/Pay Per Night - Blue.svg';
import iTopBays from'../images/preview/Parking - Blue.svg';
import iTopFacilities from'../images/preview/Facilities Only - Blue.svg';


import iRecyclingOff from'../images/facilities/Recycling - Grey.svg';
import iBlackWaterOff from'../images/facilities/Black Water Disposal - Grey.svg';
import iElectricOff from'../images/facilities/Electric Hook Up - Grey.svg';
import iGreyWaterOff from'../images/facilities/Grey Water Disposal - Grey.svg';
import iLaundryOff from'../images/facilities/Laundry - Grey.svg';
import iOvernightOff from'../images/facilities/Overnight Parking - Grey.svg';
import iRubbishOff from'../images/facilities/Rubbish - Grey.svg';
import iShowerOff from'../images/facilities/Shower - Grey.svg';
import iToiletOff from'../images/facilities/Toilet - Grey.svg';
import iWaterOff from'../images/facilities/Water - Grey.svg';
import iWifiOff from'../images/facilities/Wifi - Grey.svg';

import iRecycling from'../images/facilities/Recycling - Coral.svg';
import iBlackWater from'../images/facilities/Black Water Disposal - Coral.svg';
import iElectric from'../images/facilities/Electric Hook Up - Coral.svg';
import iGreyWater from'../images/facilities/Grey Water Disposal - Coral.svg';
import iLaundry from'../images/facilities/Laundry - Coral.svg';
import iOvernight from'../images/facilities/Overnight Parking - Coral.svg';
import iRubbish from'../images/facilities/Rubbish - Coral.svg';
import iShower from'../images/facilities/Shower - Coral.svg';
import iToilet from'../images/facilities/Toilet - Coral.svg';
import iWater from'../images/facilities/Water - Coral.svg';
import iWifi from'../images/facilities/Wifi - Coral.svg';



import iCampra from'../images/campra/Campra Accredited - Green.svg';

import defaultSiteImage from'../images/Default-Image.jpg';
import spinner from'../images/Driving-SVG.gif';


import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';


import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import Container from '@material-ui/core/Container';


import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';


import Form from "react-bootstrap/Form";

import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline';

import parse from 'html-react-parser';


import jsPDF from 'jspdf';

//import iLogo from "../images/logo.jpg";
//import iQRLogo from "../images/qrcode_logo.svg";

import iCampraA from'../images/campra/Campra Accredited - Green.svg';
import iCampraX from'../images/campra/Campra Accredited - Grey.svg';

import LoaderButtonStandard from "../components/LoaderButtonStandard";



import QRCode from 'qrcode';
import { createCanvas, loadImage } from "node-canvas";

import '../fonts/Montserrat-Regular-normal.js';

import AutoComplete from "react-google-autocomplete";



//import CheckboxLabels from "../components/CheckboxLabels";
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },

  },
})(TextField);

const BlueTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #10385A"
    },
    color: '#10385Acc'
  },
  tooltip: {
    backgroundColor: '#10385Add',
    color: '#ffffff',
    border: "1px solid #10385A",
    fontSize: 12,
    fontFamily: "Montserrat",
    fontWeight: 400,

  },
}))(Tooltip);


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      background: "#000",
    },
  },
  imageList: {
    width: 500,
    height: 48,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  card: {
    margin: theme.spacing(2),
  },
  h4: {
    fontFamily: 'Comfortaa',
    color: '#10385A4'
  },

  dialogContent: {
    width: '100%',
    backgroundColor: '#10385A',
    paddingTop: '80px',
    height:"100vh",
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    color: 'white'
  },
  pdf: {cursor:'pointer', marginTop: '-0px', marginRight: '-8px', color:'#10385A4'},

  link: {
      color: "#F18C7E",
      "&:hover": {
          color: "#F18C7E",
          textDecoration: "underline #F18C7E"
      }
  },
  spreadBox: {
    justifyContent: "space-between"
  },

  textField: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-input": {
      color: "red"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "red"
    },
    "& .MuiInputLabel-outlined": {
      color: "red"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "red"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "red"
    },


  }



}







));
const linkHoverStyle={
  link: {
      color: "#00ff00",
      "&:hover": {
          color: "#000000",
          textDecoration: "underline #000000"
      }
  }
}


const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));
const ItemRight = styled(Paper)(({ theme }) => ({
  ...theme.typography.body,
  padding: theme.spacing(1),
  textAlign: 'right',
  color: theme.palette.text.secondary,
}));
const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Return the width so we can use it in our components
  return { width };
}




export default function Shop() {
  let classes = useStyles();
  //const { id } = useParams();
  const history = useHistory();
  const [tncs, setTncs] = useState(false);
  const [spelling, setSpelling] = useState(false);

  const { isCampra } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isPaying, setIsPaying] = useState(false);

  const [saveAddress, setSaveAddress] = useState(0);
  const [paymentState, setPaymentState] = useState(0);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [paymentResult, setPaymentResult] = useState(false);
  const [sessionValues, setSessionValues] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [orderAlertOpen, setOrderAlertOpen] = useState(false);
    const [alertVals, handleAlertChange] = useFormFields({
    title: "",
    message: "",
  });
  const [fields, handleFieldChange] = useFormFields({
    extraDirections: "",
    address: "",
    mapLink: "",
    location: {},
  });


  const [address, setAddress] = useState("");
  const [tempAddress, setTempAddress] = useState("");
  const [location, setLocation] = useState({});
  const [mapLink, setMapLink] = useState("");


    const [content, setContent] = useState("");
  const [siteOpen, setSiteOpen] = useState(false);
  //const [base64Image, setBase64Image] = useState("");
  //const [siteCode, setSiteCode] = useState("");
  //const [desc, setDesc] = useState("");
  //const [rules, setRules] = useState("");
  //const [address, setAddress] = useState("");
  //const [accreditations, setAccreditations] = useState([]);
  //const [sessionValues, setSessionValues] = React.useState([]);


  const [sites, setSites] = useState(null);

  const [freeFirst, setFreeFirst] = useState([]);

  const [aluQuantity, setAluQuantity] = React.useState([]);
  const [plaQuantity, setPlaQuantity] = React.useState([]);
  const [stiQuantity, setStiQuantity] = React.useState([]);

  const aluPrice=45; //34;
  const plaPrice=30; //26;
  const stiPrice=10; //4;
  const aluPostalPrice=5.10;
  const plaPostalPrice=5.10;
  const stiPostalPrice=2;

  const discount=0; //0.1;

  const aluTitle = "Printed Dibond (aluminium) sign";
  const plaTitle = "Printed Foamex (plastic) sign";
  const stiTitle = "A4 Vinyl Sticker";


  const { width } = useViewport();
  const breakpoint = 600;


  useEffect(() => {
    onLoad();
  }, []);



  function getSites() {
    return API.get("aires", `/sites`);
  }

  async function onLoad() {
    if (isCampra) {history.push("/")}
    try {
      const parts = window.location.href.split("?");
      if (parts.length > 1) {
        if (parts[1].indexOf('session_id=')>-1) {
          const val = parts[1].split("=")[1];
          getSession(val);
          return;
        }
      }
      const cui = await Auth.currentUserInfo();
      if (cui) {
        setName(cui.attributes['custom:name']);
        setEmail(cui.attributes.email);
        setPhone(cui.attributes['custom:phone']);
      } else {
        history.push("/");
      }
    } catch (e) {
      onError(e);
      history.push("/");
    }
    refreshSites();
  }

  async function refreshSites() {
    try {
      const result = await getSites();
      if (result.status && result.data) {
        if (result.data.length > 0) {
          setSites(result.data);
          setAluQuantity(new Array(result.data.length).fill(0));
          setPlaQuantity(new Array(result.data.length).fill(0));
          setStiQuantity(new Array(result.data.length).fill(0));
          setFreeFirst(new Array(result.data.length).fill(false));
          setIsLoading(false);
        } else {
          history.push("/sites");
          setIsLoading(false);
        }
      } else {
        history.push("/sites");
        setIsLoading(false);
      }
    } catch (e) {
      onError(e);
      history.push("/");
    }
  }

  async function getSession(sessionId) {
    const result = await retrieveSession({session_id: sessionId});
    setSessionValues([result.customer, "AireStop Signs"]);
  }

  function retrieveSession(content) {
    return API.post("aires", "/payments", {
      body: {type: 'session', content: content}
    });
  }


  function handlePaymentResultClose() {
    setSessionValues([]);
    history.push("/");
  }

  function handleAlertClose() {
    if (orderAlertOpen) {
      history.push("/");
      setOrderAlertOpen(false);
    }
    setAlertOpen(false);
  };

  function onAluQuantityChange(e, index) {
    let newArr = [...aluQuantity];
    newArr[index] = e.target.value;
    setAluQuantity(newArr); // ??
  }
  function onPlaQuantityChange(e, index) {
    let newArr = [...plaQuantity];
    newArr[index] = e.target.value;
    setPlaQuantity(newArr); // ??
  }
  function onStiQuantityChange(e, index) {
    let newArr = [...stiQuantity];
    newArr[index] = e.target.value;
    setStiQuantity(newArr); // ??
  }

  function getSubTotal() {
    let subtotal = 0;
    for (let i = 0; i < sites.length; i++) {
      subtotal += (aluQuantity[i] * aluPrice)
      subtotal += (plaQuantity[i] * plaPrice)
      subtotal += (stiQuantity[i] * stiPrice)
    }
    return subtotal;
  };
  function getPostage() {
    let postage = 0;
    const subtotal = getSubTotal();
    if (subtotal > 0) {
      postage = 5;
    }
    return postage;
  };
  function getDiscount() {
    let disc = 0;
    let aluCount = 0;
    let plaCount = 0;
    let stiCount = 0;
    for (let i = 0; i < sites.length; i++) {
      aluCount += aluQuantity[i];
      plaCount += plaQuantity[i];
      stiCount += stiQuantity[i];
    }
    if (aluCount>1) {
      disc += (aluCount * aluPrice) * discount;
    }
    if (plaCount>1) {
      disc += (plaCount * plaPrice) * discount;
    }
    if (stiCount>1) {
      disc += (stiCount * stiPrice) * discount;
    }
    return Math.ceil(disc*100) / 100;
  };

  function getTotal() {
    return getSubTotal() - getDiscount();
  };


  async function stripePayment() {
    setIsPaying(true);
    let lineItems = [];
    for (let i = 0; i < sites.length; i++) {
      if (aluQuantity[i]>0 || plaQuantity[i]>0 || stiQuantity[i]>0 || freeFirst[i]) {
        let lineItem = {site: sites[i].itemCode};
        if (aluQuantity[i]>0) {
          lineItem["alu_quantity"] = aluQuantity[i];
        }
        if (plaQuantity[i]>0) {
          lineItem["pla_quantity"] = plaQuantity[i];
        }
        if (stiQuantity[i]>0) {
          lineItem["sti_quantity"] = stiQuantity[i];
        }
        if (freeFirst[i]) {
          lineItem["sti_quantity_free"] = 1;
        }
        lineItems.push(lineItem);
      }
    }
    const currentUrl = window.location.href.split("?")[0];
    const unitAmount = parseInt(getTotal()*100);
    const metadata = {ownerId: sites[0].pk, name: name, email: email, phone: phone, line_items: JSON.stringify(lineItems), unit_amount: unitAmount, address: address, instructions: fields.extraDirections};
    let result = await createPayment({ url: currentUrl, email: email, metadata: metadata});
    if (result.status) {
      if (unitAmount>0) {
        let url = result.paymentLinks.url;
        if (url) {
          window.location.href = url;
        }
      } else {
        alertVals.title="AireStop - Success!";
        alertVals.message=<>Thank you. Your free first sign has been ordered</>;
        setIsPaying(false);
        setOrderAlertOpen(true);
      }
    } else {
      alertVals.title="AireStop - Oops!";
      alertVals.message=<>Unable to submit your order at this time, please try again later.</>;
      setIsPaying(false);
      setPaymentState(0);
      setAlertOpen(true);
    }
  }

  function createPayment(content) {
    try{
      return API.post("aires", "/payments", {
        body: {type: 'shopPayment', content: content}
      });
    } catch {
      setIsLoading(false);
    }
  }

const handleSaveAddressChange = (event) => {
  //event.preventDefault();
  setSaveAddress(event.target.checked);
}
const handleTncsChange = (event) => {
  //event.preventDefault();
  setTncs(event.target.checked);
}
const handleSpellingChange = (event) => {
  //event.preventDefault();
  setSpelling(event.target.checked);
}


const handleChangeAddress = (e) => {
  setTempAddress(e.target.value);
}


function continuePaymentClick(e) {
  e.preventDefault();
  stripePayment();
}

function advancePaymentState(e) {
  e.preventDefault();
  setTncs(false);
  setPaymentState(paymentState + 1);
}

function canAdvancePaymentState(e) {
  let canAdvance = getSubTotal()>0;
  if (!canAdvance) {
    for (let i=0;i<freeFirst.length;i++) {
      if (freeFirst[i]) {
        return true;
      }
    }
  }
  return canAdvance;
}
function canMakePayment(e) {
  if (address==="") {return false}
  if (!tncs || !spelling) {return false}
  let canAdvance = getSubTotal()>0;
  if (!canAdvance) {
    for (let i=0;i<freeFirst.length;i++) {
      if (freeFirst[i]) {
        return true;
      }
    }
  }
  return canAdvance;
}

const tandcs = (event) => {
  event.preventDefault();
  window.open("https://airestop.co.uk/docs/tncs.pdf");
};


function goBack(e) {
  e.preventDefault();
  setTncs(false);
  setPaymentState(paymentState - 1);
}

function handleFirstFreeChange(e, index) {
  let newArr = [...freeFirst];
  newArr[index] = e.target.checked;
  setFreeFirst(newArr);
}

function handleContactClick(event) {
  event.preventDefault();
  history.push("/contactus", { from: 'shop' } );
}



function renderSummary() {
  var formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });
  var pFormatter = new Intl.NumberFormat('en-GB', {
    style: 'percent',
    maximumFractionDigits:0
  });

  return (





        <div className="lander">
        <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={0} alignItems='top'>

        <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h2  className={classes.h2}>Sign Shop</h2>  </Box>
        <Paper variant="outlined">
        <Box  p={1}>
        <b>Shopping Basket ({formatter.format(getTotal())}):</b>

        {sites.map((site, index) => {
          if (aluQuantity[index]>0 || plaQuantity[index]>0 || stiQuantity[index]>0 || freeFirst[index]) {
            return (
              <>
              <br/>
              {"- for " + sites[index].content.siteName + " (" + sites[index].itemCode + ")"}
              {aluQuantity[index]>0?<><br/>&nbsp;&nbsp;&nbsp;&nbsp; {aluQuantity[index]} * {aluTitle}/s</>:""}
              {plaQuantity[index]>0?<><br/>&nbsp;&nbsp;&nbsp;&nbsp; {plaQuantity[index]} * {plaTitle}/s</>:""}
              {stiQuantity[index]>0?<><br/>&nbsp;&nbsp;&nbsp;&nbsp; {stiQuantity[index]} * {stiTitle}/s</>:""}
              {freeFirst[index]?<><br/>&nbsp;&nbsp;&nbsp;&nbsp; 1 * Free {stiTitle}</>:""}
              </>
            )
          } else {
            return (
              <>
              </>
            )
          }
      }  )}





        </Box>
    </Paper>
    <br/>
        </Box>


        <Paper variant="outlined">
        <Box  p={1}>
        <a><b>Postal Address</b></a>
        </Box>
        <Box m={1}>

        {isPaying?
          <>{address}</>
        :
          <a href={mapLink} target="_blank">{address}</a>
        }
        </Box>
        {fields.extraDirections!==""?
          <Box m={1}>
          Driver instructions: {fields.extraDirections}
          </Box>
        :""}



    <Box m={0}>


    </Box>


    </Paper><br/>

    <Paper variant="outlined">
    <Box  p={1}>
    <FormControlLabel control={<Checkbox
        disabled={isPaying}
        checked={spelling}
        onChange={handleSpellingChange}/>}
      label={
        <span>The spelling of my AireStop/s is correct.</span>
      } />

      <FormControlLabel control={<Checkbox
          disabled={isPaying}
          checked={tncs}
          onChange={handleTncsChange}/>}
        label={
          <div>
          <span>I accept the </span>
          {isPaying?
            <>terms and conditions</>
            :
            <Link onClick={tandcs} className={classes.link} color= 'secondary' hover='secondary' to={'/'}>terms and conditions</Link>
          }
          </div>
        } />
      </Box>
      </Paper><br/>

        <Box
display="flex"
justifyContent="center"
alignItems="center"
width="100%"

>
<Box display="flex" mx={1}>
<LoaderButtonStandard
type="button"
size="lg"
variant="primary"
isLoading={false}
onClick={goBack}
disabled={false}
>
Back
</LoaderButtonStandard>
</Box>
<Box display="flex" mx={1}>
<LoaderButtonStandard
type="button"
size="lg"
variant="primary"
isLoading={isPaying}
onClick={continuePaymentClick}
disabled={!canMakePayment() || isPaying}
>
{getTotal()>0?"Pay":"Order"}
</LoaderButtonStandard>
</Box></Box>
<br/>
</div>




























  )
}


function renderSummaryAddress() {
  var formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });
  var pFormatter = new Intl.NumberFormat('en-GB', {
    style: 'percent',
    maximumFractionDigits:0
  });

  return (





    <div className="lander">
    <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={0} alignItems='top'>

    <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h2  className={classes.h2}>Sign Shop</h2>  </Box>
    <Paper variant="outlined">
    <Box  p={1}>
    <b>Shopping Basket ({formatter.format(getTotal())}):</b>

    {sites.map((site, index) => {
      if (aluQuantity[index]>0 || plaQuantity[index]>0 || stiQuantity[index]>0 || freeFirst[index]) {
        return (
          <>
          <br/>
          {"- for " + sites[index].content.siteName + " (" + sites[index].itemCode + ")"}
          {aluQuantity[index]>0?<><br/>&nbsp;&nbsp;&nbsp;&nbsp; {aluQuantity[index]} * {aluTitle}/s</>:""}
          {plaQuantity[index]>0?<><br/>&nbsp;&nbsp;&nbsp;&nbsp; {plaQuantity[index]} * {plaTitle}/s</>:""}
          {stiQuantity[index]>0?<><br/>&nbsp;&nbsp;&nbsp;&nbsp; {stiQuantity[index]} * {stiTitle}/s</>:""}
          {freeFirst[index]?<><br/>&nbsp;&nbsp;&nbsp;&nbsp; 1 * Free {stiTitle}</>:""}
          </>
        )
      } else {
        return (
          <>
          </>
        )
      }
  }  )}





    </Box>
</Paper>
<br/>
    </Box>
    <Paper variant="outlined">
    <Box  p={1}>
    <a><b>*Address</b><br/>The postal address to send you order.</a>
    </Box>
    <Box width="100%" m={1}>


    <AutoComplete
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      onPlaceSelected = {(place) => {
        if (place && place.geometry && place.geometry.location) {
          setLocation({"lat": place.geometry.location.lat(), "lng": place.geometry.location.lng()});
        }
        setAddress(place.formatted_address);
        setMapLink(place.url);
        setTempAddress("");
      }}
    style={{
       width:"95%",
       color: "#222",
      borderRadius: '5px',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '8px 8px 8px 8px',
    }}
      options = {{
        types: 'address' ,
        componentRestrictions:  { country: "gb" } ,
        fields: ["formatted_address", "address_components", "url", "geometry"],
        inputAutocompleteValue: "formatted_address",
      }}
    value={tempAddress}
    onChange={handleChangeAddress}
    />
    </Box>
    <Box  px={2} py={address===""?0:1}>
    <a href={mapLink} target="_blank">{address}</a>
    </Box>

    <Box  p={1}>
    <TextField
      id="extraDirections"
      name="extraDirections"
      value={fields.extraDirections}
      onChange={handleFieldChange}
      label="Driver instructions"

      multiline
      fullWidth
       size="small"
      variant="outlined"
      inputProps={{ maxLength: 1000 }}
    />
    <Box  px={1}>
    <small> </small>
    </Box>

</Box>

<Box m={1}>


{/*
<FormControlLabel control={<Checkbox

  checked={saveAddress}
  onChange={handleSaveAddressChange}



  />} label="Save address for future purchases?" />
*/}


</Box>


    </Paper><br/>





<Box
display="flex"
justifyContent="center"
alignItems="center"
width="100%"

>
<Box display="flex" mx={1}>
<LoaderButtonStandard
type="button"
size="lg"
variant="primary"
isLoading={false}
onClick={goBack}
disabled={false}
>
Back
</LoaderButtonStandard>
<Box display="flex" mx={1}>
</Box>
<LoaderButtonStandard
type="button"
size="lg"
variant="primary"
isLoading={false}
onClick={advancePaymentState}
disabled={address===""} // should be 'is address valid?
>
Continue
</LoaderButtonStandard>
</Box>
</Box>
<br/>
</div>




























  )
}


function renderLander() {
  var formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  var pFormatter = new Intl.NumberFormat('en-GB', {
    style: 'percent',
    maximumFractionDigits:0
  });

  return (
    <div className="lander">
    <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={0} alignItems='top'>

    <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h2  className={classes.h2}>Sign Shop</h2>  </Box>
    <p>{/*Welcome to the Sign Shop.<br/><br/>*/}


    This is the place to claim your free vinyl sticker and purchase additional signage for your AireStops. We offer a range of sign types from simple vinyl stickers to robust aluminium solutions.<br/><br/>
    {/*Good signage is an important but costly part of setting up an Aire. To help get you going we apply a <b>{pFormatter.format(discount)} discount</b> on multiple orders of the same sign.<br/><br/>*/}
    Don’t see what you need? Drop us a line. All signs are made to order which allows us to print custom signs on request. <Link style={{color:"white"}} onClick={handleContactClick}>Contact us</Link>.</p>
    </Box>
    <Paper>

    <Box display="flex" flexWrap="wrap" p={1}  > <h5  className={classes.h5}>{aluTitle}</h5> @ {formatter.format(aluPrice)}ea.</Box>

    <Box px={1} pb={1}>
    <Paper>
    <Box
    display="flex"
    flexDirection="column"
    justifyContent="left"
    alignItems="left"
    width="100%"
    >
    <Box p={0} bgcolor="#eeef">
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs>
        <Box p={1} ><b>AireStop</b></Box>
      </Grid>
      <Grid itemRight xs="auto">
        <Box p={1}><b>Quantity</b></Box>
      </Grid>
      <Grid itemRight xs={3}>
        <Box m={1} p={1} display="flex" justifyContent="right" alignItems="right"><b>Cost</b></Box>
      </Grid>
    </Grid>
    </Box>

    {sites.map((site, index) => {
      return (
        <Box  p={0} bgcolor={index%2>0?"#eee3":"#eee7"}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs>
              <Box p={1} >{site.content.siteName} ({site.itemCode})</Box>
            </Grid>
            <Grid itemRight xs="auto">
              <Box p={1} >
                <FormControl variant="standard"  size="small">
                  <Select
                    id="aluQuantity"
                    name="aluQuantity"
                    value={aluQuantity[index]}
                    onChange={(e) => onAluQuantityChange(e, index)}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid itemRight xs={3}>
              <Box m={1} p={1} display="flex" justifyContent="right" alignItems="right">{formatter.format(aluPrice*aluQuantity[index])}</Box>
            </Grid>
          </Grid>
        </Box>
      );
    })}
    </Box>
    </Paper>
  </Box>






<br/>

  <Box display="flex" flexWrap="wrap" p={1}  > <h5  className={classes.h5}>{plaTitle}</h5> @ {formatter.format(plaPrice)}ea.</Box>

  <Box px={1} pb={1}>
  <Paper>
  <Box
  display="flex"
  flexDirection="column"
  justifyContent="left"
  alignItems="left"
  width="100%"

  >
  <Box p={0} bgcolor="#eeef">
  <Grid container spacing={2} justifyContent="center" alignItems="center">
    <Grid item xs>
      <Box p={1} ><b>AireStop</b></Box>
    </Grid>
    <Grid itemRight xs="auto">
      <Box p={1}><b>Quantity</b></Box>
    </Grid>
    <Grid itemRight xs={3}>
      <Box m={1} p={1} display="flex" justifyContent="right" alignItems="right"><b>Cost</b></Box>
    </Grid>
  </Grid>
  </Box>

  {sites.map((site, index) => {
    return (
      <Box  p={0} bgcolor={index%2>0?"#eee3":"#eee7"}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs>
          <Box p={1} >{site.content.siteName} ({site.itemCode})</Box>
          </Grid>
          <Grid itemRight xs="auto">
            <Box p={1} >
              <FormControl variant="standard"  size="small">
                <Select
                  id="plaQuantity"
                  name="plaQuantity"
                  value={plaQuantity[index]}
                  onChange={(e) => onPlaQuantityChange(e, index)}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid itemRight xs={3}>
            <Box m={1} p={1} display="flex" justifyContent="right" alignItems="right">{formatter.format(plaPrice*plaQuantity[index])}</Box>
          </Grid>
        </Grid>
      </Box>
    );
  })}
  </Box>
  </Paper>
</Box>





<br/>

  <Box display="flex" flexWrap="wrap" p={1}  > <h5  className={classes.h5}>{stiTitle}</h5> @ {formatter.format(stiPrice)}ea.</Box>

  <Box px={1} pb={1}>
  <Paper>
  <Box
  display="flex"
  flexDirection="column"
  justifyContent="left"
  alignItems="left"
  width="100%"
  >
  <Box p={0} bgcolor="#eeef">
  <Grid container spacing={2} justifyContent="center" alignItems="center">
    <Grid item xs>
      <Box p={1} ><b>AireStop</b></Box>
    </Grid>
    <Grid itemRight xs="auto">
      <Box p={1}><b>Quantity</b></Box>
    </Grid>
    <Grid itemRight xs={3}>
      <Box m={1} p={1} display="flex" justifyContent="right" alignItems="right"><b>Cost</b></Box>
    </Grid>
  </Grid>
  </Box>

  {sites.map((site, index) => {
    return (
      <Box  p={0} bgcolor={index%2>0?"#eee3":"#eee7"}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs>
            <Box mx={1} mt={1} mb={!site.itemStatus2 || site.itemStatus2<1?0:1}>{site.content.siteName} ({site.itemCode})</Box>
            {!site.itemStatus2 || site.itemStatus2<1?
            <Box mx={1} mb={0} >

            <FormControlLabel control={<Checkbox
               size="small"
              checked={freeFirst[index]}
              onChange={(e) => {handleFirstFreeChange(e, index);}}



              />} style={{color:"#F18C7E"}} label="Claim Free Sign" />

            {/*}
              <Link  style={{cursor: 'pointer', color: '#F18C7E', textDecoration: "underline #f00"}} onClick={(e) => {handleFirstFreeClick(e, index);}} >
                <b>order free first sign</b>
              </Link>
{*/}


            </Box>
            :""}
          </Grid>
          <Grid itemRight xs="auto">
            <Box p={1} >
              <FormControl variant="standard"  size="small">
                <Select
                  id="stiQuantity"
                  name="stiQuantity"
                  value={stiQuantity[index]}
                  onChange={(e) => onStiQuantityChange(e, index)}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid itemRight xs={3}>
            <Box m={1} p={1} display="flex" justifyContent="right" alignItems="right">{formatter.format(stiPrice*stiQuantity[index])}</Box>
          </Grid>
        </Grid>
      </Box>
    );
  })}
  </Box>
  </Paper>
</Box>




<br/>
<Box px={1} pb={1}>
<Paper>
<Box
display="flex"
flexDirection="column"
justifyContent="left"
alignItems="left"
width="100%"

>
<Box p={0} bgcolor="#eee5">
<Grid container spacing={2} justifyContent="center" alignItems="center">
  <Grid item xs>
    <Box p={1} ></Box>
  </Grid>
  <Grid itemRight xs="auto">
    <Box p={1}><b>Sub Total</b></Box>
  </Grid>
  <Grid itemRight xs={3}>
    <Box m={1} p={1} display="flex" justifyContent="right" alignItems="right"><b>{formatter.format(getSubTotal())}</b></Box>
  </Grid>
</Grid>
</Box>

{/*}
<Box p={0} bgcolor="#eeea">
<Grid container spacing={2} justifyContent="center" alignItems="center">
  <Grid item xs>
    <Box p={1} ></Box>
  </Grid>
  <Grid itemRight xs="auto">
    <Box p={1}><b>Discount</b></Box>
  </Grid>
  <Grid itemRight xs={3}>
    <Box m={1} p={1} display="flex" justifyContent="right" alignItems="right"><b>{formatter.format(getDiscount())}</b></Box>
  </Grid>
</Grid>
</Box>
*/}

<Box p={0} bgcolor="#eeef">
<Grid container spacing={2} justifyContent="center" alignItems="center">
  <Grid item xs>
    <Box p={1} ></Box>
  </Grid>
  <Grid itemRight xs="auto">
    <Box p={1}><b>Total</b></Box>
  </Grid>
  <Grid itemRight xs={3}>
    <Box m={1} p={1} display="flex" justifyContent="right" alignItems="right"><b>{formatter.format(getTotal())}</b></Box>
  </Grid>
</Grid>
</Box>


</Box></Paper></Box>


    </Paper>
<br/>





<Box
display="flex"
flexDirection="column"
justifyContent="center"
alignItems="center"
width="100%"

>
    <LoaderButtonStandard
    type="button"
    size="lg"
    variant="primary"
    isLoading={false}
    onClick={advancePaymentState}
    disabled={!canAdvancePaymentState()}
    >
    Continue
    </LoaderButtonStandard>
</Box>
<br/>
</div>


  )
}


function renderLoader() {
  return (
    <div id="splash" style={{paddingTop: 100, zIndex:999}}>

      <center id="splash">
        <img src={spinner} height="30px" width="auto"/>
        {/*}<Typography style={{padding: '4px'}} variant="caption">{label}</Typography>*/}
      </center>
    </div>


  );

}






  return (

    <div className="Shop">
      {sessionValues.length == 2?<></>:(isLoading?renderLoader():(paymentState==0?renderLander():(paymentState==1?renderSummaryAddress():renderSummary())))}
      <Dialog
      open={alertOpen || orderAlertOpen}
      fullWidth={false}
      maxWidth = {'md'}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      >
      <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
      {alertVals.title}
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
      <Typography>
      {alertVals.message}
      </Typography>


      </DialogContentText>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleAlertClose} color="primary">
      Ok
      </Button>
      </DialogActions>
      </Dialog>

      <Dialog
        maxWidth='xs'
         open={sessionValues.length == 2}
         onClose={handlePaymentResultClose}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
       >
         <DialogTitle id="alert-dialog-title">{"Payment Successful"}</DialogTitle>
         <DialogContent>
           <DialogContentText id="alert-dialog-description">
               Thank you {sessionValues[0]}, your payment for {sessionValues[1]} was successful. You will receive a receipt by email.
           </DialogContentText>
         </DialogContent>
         <DialogActions>
           <Button onClick={handlePaymentResultClose} color="primary" >
             Ok
           </Button>
         </DialogActions>
       </Dialog>

    </div>
  );
}
