import Card from "./Card";
import PropTypes from "prop-types";
import styles from "./ContentComponentUnlockThePote.module.css";

const ContentComponentUnlockThePote = ({ className = "" }) => {
  return (
    <section
      className={[styles.contentcomponentUnlockthepote, className].join(" ")}
    >
      <div className={styles.container}>
        <div className={styles.contentwarp}>
          <div className={styles.infomation}>
            <div className={styles.textWrapper}>
              <h1 className={styles.zermattCardTitle}>
                Unlock the potential of your land
              </h1>
              <h3 className={styles.zermattCardDescription}>
                Hosting with AireStop offers a unique opportunity to monetise
                your unused space. As a host, you'll enjoy a steady stream of
                income, increase foot-fall on your site, and contribute to
                sustainable tourism. Our platform makes it easy to manage
                bookings, set your own rules, and control your hosting schedule.
                Whether you have a scenic rural property or an urban parking
                space, AireStop can help you turn it into a profitable aire.
              </h3>
            </div>
            <div className={styles.cta}>
              <div className={styles.ctaChild} />
              <div className={styles.locobutton}>
                <div className={styles.button}>Learn more</div>
                <img className={styles.icon} alt="" src="/icon2.svg" />
              </div>
            </div>
          </div>
          <div className={styles.graphicwrapper}>
            <Card
              solidIcon="/feature-icons1@2x.png"
              title="Extra income"
              locateAnAireUsingYourPref="Earn money from your unused land or parking spaces"
            />
            <Card
              solidIcon="/feature-icons-11@2x.png"
              title="Flexibility"
              locateAnAireUsingYourPref="Set your own fees, availability and rules."
            />
            <Card
              solidIcon="/feature-icons-21.svg"
              title="Support local tourism"
              locateAnAireUsingYourPref="Promote local businesses and contribute to your area's economy"
            />
            <Card
              solidIcon="/feature-icons-3@2x.png"
              title="Low investment"
              locateAnAireUsingYourPref="Minimal setup costs compared to traditional alternatives"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

ContentComponentUnlockThePote.propTypes = {
  className: PropTypes.string,
};

export default ContentComponentUnlockThePote;
