import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";

import { API } from "aws-amplify";
import "./VisitorBook.css";
//import LoaderButton2 from "../components/LoaderButton2";
//import LoaderButton3 from "../components/LoaderButton3";

import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { BsArrowRepeat } from "react-icons/bs";


import Table from "react-bootstrap/Table";

import { makeStyles, withStyles, styled } from '@material-ui/core/styles';


import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Button from '@material-ui/core/Button';
//import Button from "../components/LoaderButtonX";
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

import ButtonBase from '@material-ui/core/ButtonBase';


import Fab from '@material-ui/core/Fab';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';



import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

//import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
//import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
//import Brightness3Icon from '@material-ui/icons/Brightness3';
//import InvertColorsIcon from '@material-ui/icons/InvertColors';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

//import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';

import iTopOvernightOff from '../images/preview/Pay Per Night - Grey.svg';
import iTopBaysOff from '../images/preview/Parking - Grey.svg';
import iTopFacilitiesOff from '../images/preview/Facilities Only - Grey.svg';

import iTopOvernight from '../images/preview/Pay Per Night - Blue.svg';
import iTopBays from '../images/preview/Parking - Blue.svg';
import iTopFacilities from '../images/preview/Facilities Only - Blue.svg';


import iRecyclingOff from '../images/facilities/Recycling - Grey.svg';
import iBlackWaterOff from '../images/facilities/Black Water Disposal - Grey.svg';
import iElectricOff from '../images/facilities/Electric Hook Up - Grey.svg';
import iGreyWaterOff from '../images/facilities/Grey Water Disposal - Grey.svg';
import iLaundryOff from '../images/facilities/Laundry - Grey.svg';
import iOvernightOff from '../images/facilities/Overnight Parking - Grey.svg';
import iRubbishOff from '../images/facilities/Rubbish - Grey.svg';
import iShowerOff from '../images/facilities/Shower - Grey.svg';
import iToiletOff from '../images/facilities/Toilet - Grey.svg';
import iWaterOff from '../images/facilities/Water - Grey.svg';
import iWifiOff from '../images/facilities/Wifi - Grey.svg';

import iRecycling from '../images/facilities/Recycling - Coral.svg';
import iBlackWater from '../images/facilities/Black Water Disposal - Coral.svg';
import iElectric from '../images/facilities/Electric Hook Up - Coral.svg';
import iGreyWater from '../images/facilities/Grey Water Disposal - Coral.svg';
import iLaundry from '../images/facilities/Laundry - Coral.svg';
import iOvernight from '../images/facilities/Overnight Parking - Coral.svg';
import iRubbish from '../images/facilities/Rubbish - Coral.svg';
import iShower from '../images/facilities/Shower - Coral.svg';
import iToilet from '../images/facilities/Toilet - Coral.svg';
import iWater from '../images/facilities/Water - Coral.svg';
import iWifi from '../images/facilities/Wifi - Coral.svg';



import iCampra from '../images/campra/Campra Accredited - Green.svg';

import defaultSiteImage from '../images/Default-Image-Road.jpg';
import spinner from '../images/Driving-SVG.gif';


import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';


import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import Container from '@material-ui/core/Container';


import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';


import Form from "react-bootstrap/Form";

import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline';

import parse from 'html-react-parser';


import jsPDF from 'jspdf';

import iLogo from "../images/logo.jpg";
import iQRLogo from "../images/qrcode_logo.svg";

import iCampraA from '../images/campra/Campra Accredited - Green.svg';
import iCampraX from '../images/campra/Campra Accredited - Grey.svg';


import QRCode from 'qrcode';
import { createCanvas, loadImage } from "node-canvas";

import '../fonts/Montserrat-Regular-normal.js';


//import Calendar from 'react-calendar';
//import 'react-calendar/dist/Calendar.css';

//import CalendarPicker from 'react-native-calendar-picker';

//import DatePicker from "react-multi-date-picker";
import { Calendar, DateObject } from "react-multi-date-picker"
import "react-multi-date-picker/styles/colors/xxx.css"
import "react-multi-date-picker/styles/colors/red.css"
import colors from "react-multi-date-picker/plugins/colors"

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Collapse from '@material-ui/core/Collapse';











//import CheckboxLabels from "../components/CheckboxLabels";
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },

  },
})(TextField);

const BlueTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #10385A"
    },
    color: '#10385Acc'
  },
  tooltip: {
    backgroundColor: '#10385Add',
    color: '#ffffff',
    border: "1px solid #10385A",
    fontSize: 12,
    fontFamily: "Montserrat",
    fontWeight: 400,

  },
}))(Tooltip);


const useStyles = makeStyles((theme) => ({


  root: {
    '& > *': {
      margin: theme.spacing(1),
      background: "#000",
    },
  },
  imageList: {
    width: 500,
    height: 48,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  card: {
    margin: theme.spacing(2),
  },
  h4: {
    fontFamily: 'Comfortaa',
    color: '#10385A4'
  },
  h6: {
    color: '#F18C7E'
  },

  dialogContent: {
    width: '100%',
    backgroundColor: '#10385A',
    paddingTop: '80px',
    height: "100vh",
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  divcontrol: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  label: {
    display: "flex"
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    color: 'white'
  },
  pdf: { cursor: 'pointer', marginTop: '-0px', marginRight: '-8px', color: '#10385A4' },

  link: {
    color: "#F18C7E",
    "&:hover": {
      color: "#F18C7E",
      textDecoration: "underline #F18C7E"
    }
  },
  spreadBox: {
    justifyContent: "space-between"
  },

  textField: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-input": {
      color: "red"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "red"
    },
    "& .MuiInputLabel-outlined": {
      color: "red"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "red"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "red"
    },


  }



}







));

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Return the width so we can use it in our components
  return { width };
}


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Sites() {
  let classes = useStyles();

  const history = useHistory();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    reg: "",
  });
  const [tncs, setTncs] = useState(false);

  const { isCampra } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);
  const [switchingId, setSwitchingId] = useState(null);

  const [open, setOpen] = React.useState([]);
  const [paymentResult, setPaymentResult] = React.useState(false);
  const [sessionValues, setSessionValues] = React.useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [alertVals, handleAlertChange] = useFormFields({
    title: "",
    message: "",
  });



  const [currentSite, setCurrentSite] = useState(null);
  const [content, setContent] = useState("");
  const [siteOpen, setSiteOpen] = useState(false);
  //const [base64Image, setBase64Image] = useState("");
  //const [siteCode, setSiteCode] = useState("");
  //const [desc, setDesc] = useState("");
  //const [rules, setRules] = useState("");
  const [address, setAddress] = useState("");
  //const [accreditations, setAccreditations] = useState([]);


  const [sites, setSites] = useState([]);



  const [calendarVal, onCalendarVal] = useState([
    //new DateObject().setDay(5),
    ///new DateObject().setDay(12).color="red",
    //new DateObject().setDay(14).add(1, "month"),
    //new DateObject().setDay(23).add(1, "month"),

  ]);

  const [closedDatesChanged, setClosedDatesChanged] = React.useState([]);
  const [calendarExpanded, setCalendarExpanded] = React.useState([]);
  const handleExpandCalendarClick = (idx) => {
    const curr = calendarExpanded[idx];
    let newArr = new Array(calendarExpanded.length).fill(false);
    newArr[idx] = !curr;
    setCalendarExpanded(newArr);
  };


  const [forceUpdate, setForceUpdate] = React.useState(false);




  const { width } = useViewport();
  const breakpoint = 600;

  useEffect(() => {

    onLoad();
  }, []);






  const [tabSite, setTabSite] = useState(0);






  /*
  function getDayRange() {
    var d = new Date();
    d.setHours(0,0,0,0);
    var t1 = d.getTime();
    return {start: t1, end: t1 + 86400000};
  }
  */

  function getOpenStatus(status, dates) {
    if (status < 2) {
      return status === 1;
    } else if (status == 2) {
      if (!dates) {
        return true;
      }
      const d = new Date();
      d.setHours(12, 0, 0, 0);
      const dt = d.getTime();
      var found = dates.find(function (element) {
        //console.log(element == dt);
        return element == dt;
      });
      //console.log(dt);
      //console.log(dates);
      //console.log(found!==dt);
      return found !== dt;
    } else {
      return false;
    }
  }

  function getTransactions() {
    return API.post("aires", `/tran`, {
      body: { all: true }
    });
  }

  function getTransactionsByAdminForSite(siteId) {
    return API.post("aires", `/tranadmin`, {
      body: { siteId: siteId }
    });
  }


  function getSites() {
    return API.get("aires", `/sites`);
  }
  function getSitesByAdmin() {
    return API.get("aires", `/sitesadmin`);
  }

  async function onLoad() {
    if (isCampra) { history.push("/") }
    setIsLoading(true);
    try {




      const result = await getSites();
      //const result = await getSitesByAdmin();




      if (result.status && result.data) {
        var calendarObjects = [];
        const openArray = new Array(result.data.length).fill(false);
        result.data.forEach((site, index) => {
          //site["trans"] = { "overnight": [] };
          //site.trans["overnightNextDay"] = [];
          //site.trans["facilities"] = [];
          var dateObjects = [];
          if (site.content.closedDates) {
            site.content.closedDates.forEach(ts => {
              const dobj = new DateObject({
                date: ts
              })
              dateObjects.push(dobj);
            });
            //console.log(dateObjects);
          }
          calendarObjects.push(dateObjects);
          openArray[index] = getOpenStatus(site.itemStatus, site.content.closedDates);
        });
        setOpen(openArray);
        onCalendarVal(calendarObjects);

        result.data.push({ add: true });
        setSites(result.data);
        const expandArray = new Array(result.data.length).fill(false);
        setCalendarExpanded(expandArray);
        setClosedDatesChanged(expandArray);
        //console.log(result);
        if (result.data.length > 1) {



          const trans = await getTransactions();
          //const trans = await getTransactionsByAdminForSite('V10407');


          //console.log(trans);
          if (trans.status && trans.data && trans.data.length > 0) {
            const today = new Date();
            today.setHours(12, 0, 0, 0);
            const t1 = today.getTime();
            const t2 = t1 + (24 * 60 * 60 * 1000);
            const twoHours = 2 * 60 * 60 * 1000;

            const sortedTransactions = trans.data.sort((a, b) => {
              if (a.content.metadata.siteCode === b.content.metadata.siteCode) {
                return b.content.metadata.whenDate.localeCompare(a.content.metadata.whenDate);
              } else {
                return a.content.metadata.siteCode - b.content.metadata.siteCode;
              }
            });

            //console.log(sortedTransactions);


            result.data.forEach(site => {
              if (site.content) {
                site["trans"] = {};
                site.trans["today"] = [];
                site.trans["tomorrow"] = [];
                site.trans["previous"] = [];
                //console.log(site.content.siteName);
                sortedTransactions.filter((trn) => trn.content.metadata.siteCode === site.GSI1SK).map(tran => {
                  const whenDate = parseInt(tran.content.metadata.whenDate);
                  const obj = { date: tran.content.metadata.whenDate, type: tran.content.metadata.payType, reg: tran.vreg };
                  if (Math.abs(t1 - whenDate) < twoHours) {
                    site.trans.today.push(obj);
                  } else if (Math.abs(t2 - whenDate) < twoHours) {
                    site.trans.tomorrow.push(obj);
                  } else {
                    site.trans.previous.push(obj);
                  }

                  /*
                  
                  
                                  if (tran.content.metadata.payType === "overnight") {
                                    const whenDate = parseInt(tran.content.metadata.whenDate);
                                    //console.log("overnight");
                                    //console.log(Math.abs(t1 - whenDate)<twoHours);
                                    //console.log(Math.abs(t2 - whenDate)<twoHours);
                                    //if (t1 == whenDate) {
                                    if (Math.abs(t1 - whenDate) < twoHours) {
                                      site.trans.overnight.push(tran.vreg);
                                      //console.log(1,tran.vreg);
                                      //} else if (t2 == whenDate) {
                                    } else if (Math.abs(t2 - whenDate) < twoHours) {
                                      site.trans.overnightNextDay.push(tran.vreg);
                                      //console.log(2,tran.vreg);
                                    }
                                  } if (tran.content.metadata.payType === "facilities") {
                                    site.trans.facilities.push(tran.vreg);
                                    //console.log(3,tran.vreg);
                                  }
                  
                  
                  */

                })
              }
            });

            /*
            
                        trans.data.forEach(tran => {
                          const code = tran.GSI1SK.split("-")[0];
                          //console.log(code);
                          var site = result.data.find(obj => {
                            return obj.itemCode === code;
                          })
                          //console.log(site);
                          if (tran.content.metadata.payType === "overnight") {
                            const whenDate = parseInt(tran.content.metadata.whenDate);
                            //console.log("overnight");
                            //console.log(Math.abs(t1 - whenDate)<twoHours);
                            //console.log(Math.abs(t2 - whenDate)<twoHours);
                            //if (t1 == whenDate) {
                            if (Math.abs(t1 - whenDate) < twoHours) {
                              site.trans.overnight.push(tran.vreg);
                              //console.log(1,tran.vreg);
                              //} else if (t2 == whenDate) {
                            } else if (Math.abs(t2 - whenDate) < twoHours) {
                              site.trans.overnightNextDay.push(tran.vreg);
                              //console.log(2,tran.vreg);
                            }
                          } if (tran.content.metadata.payType === "facilities") {
                            site.trans.facilities.push(tran.vreg);
                            //console.log(3,tran.vreg);
                          }
                        });
            
                        */


          }
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        alertVals.title = "AireStop - Oops!";
        alertVals.message = "Unable to retrieve sites, please check your internet connection and try later.";
        setAlertOpen(true);
      }
    } catch (e) {
      onError(e);
      history.push("/");
    }
  }



  const OpenClosedSwitch = withStyles({
    switchBase: {
      color: red[700],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);


  function handleAlertClose() {
    setAlertOpen(false);
    setDeleteAlertOpen(false);
  };


  function handleNewSiteClick() {
    history.push("/sites/create/new");
  }

  function handleEditSiteClick(site) {
    history.push("/sites/" + site.itemCode + "/edit");
  }



  /*
  
  {site.trans?
  <Box pb={1}>
  <Link component="button" disabled={site.trans.overnight.length==0} onClick={() => { handleOvernightTransClick(site) }}>Overnight: {site.trans.overnight.length}</Link> &nbsp;&nbsp;&nbsp;&nbsp;
  <Link component="button" disabled={site.trans.facilities.length==0} onClick={() => { handleFacilitiesTransClick(site) }}>Facilities: {site.trans.facilities.length}</Link>
  
    <Divider width="100%"/>
  </Box>
  :<></>}
  
  
  */



  function handleTransactionsClick(site) {
    alertVals.title = <>Todays Bookings<br /><span style={{ fontSize: "14px" }}>{site.content.siteName}</span></>;
    alertVals.message = <><b>Overnight Stays</b> <br />Today ({site.trans.overnight.length})<br />
      {site.trans.overnight.length > 0 ?

        <>Vehicle registration{site.trans.overnight.length > 1 ? "s" : ""}:<br />{
          site.trans.overnight.map((reg, index) => {
            return (
              <><i>{reg.toUpperCase()}</i><br /></>
            )
          })

        }
        </>
        :
        <></>

      }
      <br />
      Tomorrow ({site.trans.overnightNextDay.length})<br />
      {site.trans.overnightNextDay.length > 0 ?

        <>Vehicle registration{site.trans.overnightNextDay.length > 1 ? "s" : ""}:<br />{
          site.trans.overnightNextDay.map((reg, index) => {
            return (
              <><i>{reg.toUpperCase()}</i><br /></>
            )
          })

        }
        </>
        :
        <></>

      }
      <br />
      <b>Facilities Use</b> ({site.trans.facilities.length})<br />

      {site.trans.facilities.length > 0 ?

        <>Vehicle registration{site.trans.facilities.length > 1 ? "s" : ""}:<br />{
          site.trans.facilities.map((reg, index) => {
            return (
              <><i>{reg.toUpperCase()}</i><br /></>
            )
          })
        }
        </>
        :
        <></>


      }
    </>;
    setAlertOpen(true);
  }



  function handleOrderSiteClick(site) {
    history.push("/shop");
  }
  function handleDeleteSiteClick(site) {
    alertVals.title = "AireStop - Confirm Delete";
    alertVals.message = "Are you sure you wish to delete this AireStop. This cannot be undone and your customers will lose access to this site.";
    setCurrentSite(site);
    setDeleteAlertOpen(true);
  }

  async function handleSiteDelete(e) {
    e.preventDefault();
    setIsLoading(true);
    setDeleteAlertOpen(false);
    const newStatus = 9; //DELETED
    const result = await updateSiteStatus(currentSite.itemCode, newStatus);
    if (result.status) {
      setCurrentSite(null);
    }
    onLoad(); //refresh sites list
  }

  function onCalendarValChange(idx, e) {
    //console.log(e);
    //console.log(idx);
    calendarVal[idx] = e;
    onCalendarVal(calendarVal);
    determineClosedDatesChanged(idx);
  }

  function determineClosedDatesChanged(idx) {
    var oldDates = sites[idx].content.closedDates;
    var dates = [];
    calendarVal[idx].forEach(dateObj => {
      const ts = dateObj.valueOf();
      const dt = new Date(ts);
      dt.setHours(12, 0, 0, 0);
      dates.push(dt.getTime());
    });
    dates.sort();
    const aaa = dates.toString();
    var bbb = "";
    if (oldDates) {
      oldDates.sort();
      bbb = oldDates.toString();
    }
    //console.log(aaa);
    //console.log(bbb);
    closedDatesChanged[idx] = aaa !== bbb;
    setClosedDatesChanged(closedDatesChanged);
    setForceUpdate(!forceUpdate);
    //console.log(closedDatesChanged);
  }


  async function handleSaveClosedDatesClick(idx, site, e) {
    e.preventDefault();
    setSwitchingId("switching-id-" + idx);
    setIsSwitching(true);
    setForceUpdate(!forceUpdate);
    var dates = [];
    calendarVal[idx].forEach(dateObj => {
      const ts = dateObj.valueOf();
      const dt = new Date(ts);
      dt.setHours(12, 0, 0, 0);
      dates.push(dt.getTime());
    });
    const result = await updateSiteClosedDates(site.itemCode, dates);
    if (result.status) {
      site.content["closedDates"] = result.result.Attributes.content.closedDates;
      open[idx] = getOpenStatus(site.itemStatus, site.content.closedDates);
    }
    determineClosedDatesChanged(idx);
    setIsSwitching(false);
    setSwitchingId(null);
  }

  const handleStatusChange = async (event, idx) => {
    //console.log(event);
    event.preventDefault();
    setSwitchingId("switching-id-" + idx);
    setIsSwitching(true);
    const newStatus = event.target.value;
    const result = await updateSiteStatus(sites[idx].itemCode, newStatus);
    if (result.status) {
      sites[idx].itemStatus = result.result.Attributes.itemStatus;
    }
    open[idx] = getOpenStatus(sites[idx].itemStatus, sites[idx].content.closedDates);

    setOpen(open);
    setIsSwitching(false);
    setSwitchingId(null);
  };

  /*
  const handleSiteOpenChange = async (event) => {
    event.preventDefault();
    setSwitchingId(event.target.id);
    setIsSwitching(true);
    const newStatus = event.target.checked?1:0;
    const result = await updateSiteStatus(sites[event.target.id].itemCode, newStatus);
    if (result.status) {
      sites[event.target.id].itemStatus=result.result.Attributes.itemStatus;
      setSiteOpen(!siteOpen);
    }
    setIsSwitching(false);
    setSwitchingId(null);
  }
  */

  function updateSiteStatus(id, status) {
    return API.put("aires", `/sites/${id}`, {
      body: {
        type: 'STATUS',
        status: status
      }
    });
  }
  function updateSiteClosedDates(id, dates) {
    return API.put("aires", `/sites/${id}`, {
      body: {
        type: 'CLOSED_DATES',
        dates: dates
      }
    });
  }





  function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
  }

  function handleTabChange(event, value) {
    setTabSite(value);
  };

  const parentStyles = {
    // borderBottom: "3px solid gray",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  };




  function renderLoader() {
    return (
      <div id="splash" style={{ paddingTop: 100, zIndex: 999 }}>

        <center id="splash">
          <img src={spinner} height="30px" width="auto" />
          {/*}<Typography style={{padding: '4px'}} variant="caption">{label}</Typography>*/}
        </center>
      </div>


    );

  }


  function renderLander() {
    return (
      <div style={isSwitching ? { pointerEvents: "none", opacity: "0.7" } : {}}>
        <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0} mx={2} alignItems='top'>
          <Box display="flex" flexWrap="wrap" mb={0} mx={0} alignItems='top'> <h2 className={classes.h2}>Visitor Book</h2>  </Box>
          <Box mx={0.5}>
            {sites.length > 1 ?
              <p>See the details of the guests who have visited your site{sites.length > 1 ? "s" : ""}.</p>
              :
              <p>You do not have any sites yet. Please first create your first AireStop site.</p>
            }
          </Box>
        </Box>
        <Box display="flex" width="100%" flexDirection="column" justifyContent="center" alignItems='center'>
          {sites.length > 1 ? renderSites() : null}
        </Box>
      </div>
    );
  }

  function renderSites() {

    return (
      <>
        <Box maxWidth="650px">
          <Tabs
            value={tabSite}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="false"
          >
            {sites.map((site, index) => {

              return (site.add ? <></> : <Tab label={site.content.siteName} />)
            })}
          </Tabs>
          {sites && sites.length > 1 && tabSite >= 0 && renderSiteDetails()}
        </Box>
      </>
    );
  }

  function renderSiteDetails() {
    return (
      <>
        <Paper elevation={0} >
          <Box px={1} py={4} maxWidth="650px">
            <Box display="flex" flexWrap="wrap" mb={0} mx={0} py={0} my={-1} alignItems='top'> <h4 className={classes.h2}>Today</h4>  </Box>
            <Box px={0} pb={3}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="left"
                alignItems="left"
                width="100%"
              >
                <Box p={0}>
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs>
                      <Box py={1} ><b>Date</b></Box>
                    </Grid>
                    <Grid itemRight xs="auto">
                      <Box py={1}><b>Service</b></Box>
                    </Grid>
                    <Grid itemRight xs={3}>
                      <Box m={1} py={1} display="flex" justifyContent="right" alignItems="right"><b>Vehicle Reg.</b></Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider style={{ height: "2px" }} />

                {sites[tabSite].trans.today.map((trn, index) => {
                  return (
                    <>
                      <Box p={0} >
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                          <Grid item xs>
                            <Box py={1} >{new Date(Number(trn.date)).toLocaleDateString(undefined, { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-')} </Box>
                          </Grid>
                          <Grid itemRight xs="auto">
                            <Box py={1} >
                              <BlueTooltip arrow placement="left" enterTouchDelay={100} enterDelay={50} title={trn.type == "overnight" ? "Overnight Parking (including facilities use)" : trn.type == "facilities" ? "Facilities use only" : ""}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3} mx={1} alignItems='center'><img src={trn.type == "overnight" ? iTopOvernight : trn.type == "facilities" ? iTopFacilities : ""} width='auto' height='28' /></Box></BlueTooltip>
                            </Box>
                          </Grid>
                          <Grid itemRight xs={3}>
                            <Box m={1} py={1} display="flex" justifyContent="right" alignItems="right">{trn.reg}</Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider style={{ height: "1px" }} />
                    </>
                  );
                })}
              </Box>
            </Box>
            <Box display="flex" flexWrap="wrap" mb={0} mx={0} py={0} my={-1} alignItems='top'> <h4 className={classes.h2}>Tomorrow</h4>  </Box>
            <Box px={0} pb={3}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="left"
                alignItems="left"
                width="100%"
              >
                <Box p={0}>
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs>
                      <Box py={1} ><b>Date</b></Box>
                    </Grid>
                    <Grid itemRight xs="auto">
                      <Box py={1}><b>Service</b></Box>
                    </Grid>
                    <Grid itemRight xs={3}>
                      <Box m={1} py={1} display="flex" justifyContent="right" alignItems="right"><b>Vehicle Reg.</b></Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider style={{ height: "2px" }} />

                {sites[tabSite].trans.tomorrow.map((trn, index) => {
                  return (
                    <>
                      <Box p={0} >
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                          <Grid item xs>
                            <Box py={1} >{new Date(Number(trn.date)).toLocaleDateString(undefined, { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-')} </Box>
                          </Grid>
                          <Grid itemRight xs="auto">
                            <Box py={1} >
                              <BlueTooltip arrow placement="left" enterTouchDelay={100} enterDelay={50} title={trn.type == "overnight" ? "Overnight Parking (including facilities use)" : trn.type == "facilities" ? "Facilities use only" : ""}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3} mx={0} alignItems='center'><img src={trn.type == "overnight" ? iTopOvernight : trn.type == "facilities" ? iTopFacilities : ""} width='auto' height='28' /></Box></BlueTooltip>
                            </Box>
                          </Grid>
                          <Grid itemRight xs={3}>
                            <Box m={1} py={1} display="flex" justifyContent="right" alignItems="right">{trn.reg}</Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider style={{ height: "1px" }} />
                    </>
                  );
                })}
              </Box>
            </Box>
            <Box display="flex" flexWrap="wrap" mb={0} mx={0} py={0} my={-1} alignItems='top'> <h4 className={classes.h2}>Previous</h4>  </Box>
            <Box px={0} pb={1}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="left"
                alignItems="left"
                width="100%"
              >
                <Box p={0}>
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs>
                      <Box py={1} ><b>Date</b></Box>
                    </Grid>
                    <Grid itemRight xs="auto">
                      <Box py={1}><b>Service</b></Box>
                    </Grid>
                    <Grid itemRight xs={3}>
                      <Box m={1} py={1} display="flex" justifyContent="right" alignItems="right"><b>Vehicle Reg.</b></Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider style={{ height: "2px" }} />

                {sites[tabSite].trans.previous.map((trn, index) => {
                  return (
                    <>
                      <Box p={0} >
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                          <Grid item xs>
                            <Box py={1} >{new Date(Number(trn.date)).toLocaleDateString(undefined, { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-')} </Box>
                          </Grid>
                          <Grid itemRight xs="auto">
                            <Box py={1} >
                              <BlueTooltip arrow placement="left" enterTouchDelay={100} enterDelay={50} title={trn.type == "overnight" ? "Overnight Parking (including facilities use)" : trn.type == "facilities" ? "Facilities use only" : ""}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3} mx={1.5} alignItems='center'><img src={trn.type == "overnight" ? iTopOvernight : trn.type == "facilities" ? iTopFacilities : ""} width='auto' height='28' /></Box></BlueTooltip>
                            </Box>
                          </Grid>
                          <Grid itemRight xs={3}>
                            <Box m={1} py={1} display="flex" justifyContent="right" alignItems="right">{trn.reg}</Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider style={{ height: "1px" }} />
                    </>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Paper>
      </>
    );

  }

  /*
    function renderSites() {
      return sites.map((site, index) => {
        return (
          <>
  
          <Box maxWidth="650px">
          <Card className={classes.card}>
            <CardContent>
              {site.add?renderAdd():renderSite(site, index)}
            </CardContent>
          </Card>
          </Box>
          </>
        );
      })
    }
  */

  return (

    <div className="Sites">
      {isLoading ? renderLoader() : renderLander()}
    </div>
  );
}