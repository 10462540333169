import PropTypes from "prop-types";
import styles from "./ContentComponentThinkingOfHos.module.css";
import { useHistory } from "react-router-dom";

const ContentComponentThinkingOfHos = ({ className = "" }) => {
  const history=useHistory();

  function handleThinking(event) {
    event.preventDefault();
    history.push("/hosting");
    //setExpanded(false);
  }
  return (
    <section
      className={[styles.contentcomponentThinkingofhos, className].join(" ")}
    >
      <div className={styles.container}>
        <div className={styles.contentwarp}>
          <div className={styles.infomation}>
            <div className={styles.textWrapper}>
              <h1
                className={styles.zermattCardTitle}
              >{`Thinking of Hosting? `}</h1>
              <div className={styles.zermattCardDescriptionContainer}>
                <p
                  className={styles.airestopEmpowersHosts}
                >{`AireStop empowers hosts to manage their aire with complete autonomy. As a host, you have full control over your stop, including opening hours, facilities offered, guest rules, and pricing. `}</p>
                <p className={styles.blankLine}>&nbsp;</p>
                <p
                  className={styles.ourUserFriendlyInterface}
                >{`Our user-friendly interface allows you to easily update your profile, manage bookings, communicate with guests, and accept secure payments, all without needing to be on site. `}</p>
                <p className={styles.blankLine1}>&nbsp;</p>
                <p
                  className={styles.andTheBest}
                >{`And the best bit, its completely free for Hosts. `}</p>
              </div>
            </div>
            <div className={styles.cta}>
              <div className={styles.ctaChild} />
              <button className={styles.locobutton} onClick={handleThinking}>
                <a className={styles.button}>Learn more</a>
                <img className={styles.icon} alt="" src="/icon2.svg" />
              </button>
            </div>
          </div>
          <div className={styles.graphicwrapper}>
            <div className={styles.illustrations}>
              <img
                className={styles.vectorIcon}
                loading="lazy"
                alt=""
                src="/vector1.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContentComponentThinkingOfHos.propTypes = {
  className: PropTypes.string,
};

export default ContentComponentThinkingOfHos;
