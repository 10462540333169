import PropTypes from "prop-types";
import styles from "./HeroSectionSiteSearch.module.css";
import React, { useState, useEffect } from "react";

const HeroSectionSiteSearch = ({ className = "", onScanClick, onSiteIdClick}) => {
  const [siteId, setSiteId] = useState("");
  const eventhandler = data => {setSiteId(data.target.value) }
  const eventhandler2 = event=>{onSiteIdClick(event, siteId)}

  return (
    <section className={[styles.herosectionSitesearch, className].join(" ")}>
      <div className={styles.container}>
        <div className={styles.headlinetext}>
          <h1 className={styles.findPay}>Find - Pay - Stay</h1>
          <h3
            className={styles.toFindYour}
          >{`To find your stop, enter a site code or scan the QR code. `}</h3>
        </div>
        <div className={styles.searchcontainer}>
          <div className={styles.searchbar}>
            <div className={styles.inputField}>
              <div className={styles.inputFieldBase}>
                <div className={styles.email}>Site Code</div>
                <div className={styles.iconParent}>
                  <img className={styles.icon} alt="" src="/icon.svg" />
                  <input id="siteidTextField"
                    onChange={eventhandler}
                    className={styles.jeraldinelocofyuiai}
                    placeholder="Enter Site Code"
                    type="text"
                    
                    
                  />
                </div>
                <div className={styles.thisIsA}>
                  This is a hint text for the user
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttoncontainer}>
            <button className={styles.locobutton} onClick={eventhandler2}>
              <div className={styles.button}>Find Site</div>
            </button>
            <button className={styles.locobutton1} onClick={onScanClick}>
              <div className={styles.button1}>Scan QR code</div>
              <img className={styles.icon1} alt="" src="/icon1.svg" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

HeroSectionSiteSearch.propTypes = {
  siteId: PropTypes.string,

  /** Action props */
  onScanClick: PropTypes.func,
  onSiteIdClick: PropTypes.func,
};


export default HeroSectionSiteSearch;
