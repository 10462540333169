import PropTypes from "prop-types";
import styles from "./CardSitePreviewSmall.module.css";

const CardSitePreviewSmall = ({ className = "", site, onSiteIdClick}) => {
  const eventhandler = event=>{onSiteIdClick(event, site.itemCode)}
  const fullPriceStay = site.content.priceStay == null || site.content.priceStay == ""?"":site.content.priceStay > 0 ? "£" + (site.content.priceStay * (1 + site.content.fee)).toFixed(2) : "free";
  const fullPriceFacilities = site.content.priceFacilities == null || site.content.priceFacilities == '' ?"":site.content.priceFacilities > 0 ? "£" + (site.content.priceFacilities * (1 + site.content.fee)).toFixed(2) : "free";
  console.log(site);


  function currencyFormat(num) {
    return '£' + Number.parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  return (
    <div className={[styles.cardsitepreviewsmall, className].join(" ")}>
      <img
        className={styles.sitePreviewImages}
        loading="lazy"
        alt=""
        src={site.image}
      />
      <div className={styles.siteinfo}>
        <div className={styles.text}>
          <div className={styles.siteName}>{site.content.siteName}</div>
          <div className={styles.addressPostcode}>{site.content.address}</div>
        </div>
        <div className={styles.siteIcons}>
          <div className={styles.content} title={site.content.priceStay == null || site.content.priceStay == ""?"No overnight parking":"Overnight parking"}>
            <img
              className={styles.siteicons}
              loading="lazy"
              alt=""
              src={site.content.priceStay == null || site.content.priceStay == ""?"/OvernightParkingOff.svg":"/OvernightParkingOn.svg"}
            />
            <div className={styles.div}>{fullPriceStay}</div>
          </div>
          <div className={styles.content1} title={site.content.priceFacilities == null || site.content.priceFacilities == '' ?"No services":"Services"}>
            <img className={styles.siteicons1} alt="" src={site.content.priceFacilities == null || site.content.priceFacilities == '' ?"/FacilityUseOff.svg":"/FacilityUseOn.svg"} />
            <div className={styles.div1}>{fullPriceFacilities}</div>
          </div>
          
          <div className={styles.content2} title={site.content.bays<=0?"No Spaces":"Spaces"}>
            <img
              className={styles.siteicons2}
              alt=""
              src={site.content.bays<=0?"/SpacesOff.svg":"/SpacesOn.svg"}
            />
            <div className={styles.empty}>{site.content.bays}</div>
          </div>
        </div>
        <div className={styles.cta}>
          <div className={styles.locobutton}  >
            
          {/*<button className={styles.button}  style={{outline: 'none'}} onClick={eventhandler}>*/}
              <div className={styles.button}  onClick={eventhandler}>Explore stop</div>
              <img className={styles.icon} alt="" src="/icon-1.svg" />
            {/*</button>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

CardSitePreviewSmall.propTypes = {
  className: PropTypes.string,
};

export default CardSitePreviewSmall;


