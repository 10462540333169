import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import "./Home.css";
import LoaderButton from "../components/LoaderButton";

import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
//import bgimage from'../images/large_camper.jpeg';
import spinner from'../images/Driving-SVG.gif';

import TextField from '@material-ui/core/TextField';
import LoaderButtonX from "../components/LoaderButtonX";
import SiteIdInput from "../components/SiteIdInput";

import { useFormFields } from "../libs/hooksLib";
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Form from "react-bootstrap/Form";
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';

//import LaunchRoundedIcon from '@material-ui/icons/LaunchRoundedIcon';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';


import QrReader from 'react-qr-reader'
import Fab from '@material-ui/core/Fab';


import { GoogleMap, useJsApiLoader, Marker, StandaloneSearchBox, InfoWindow, InfoBox } from '@react-google-maps/api';

import pinIcon from'../images/pin.svg';
import pinIconCampraDark from'../images/pincampradark.svg';
import pinIconCampraLight from'../images/pincampralight.svg';

import defaultSiteImage from'../images/Default-Image.jpg';




import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Divider from '@material-ui/core/Divider';

import iTopOvernightOff from'../images/preview/Pay Per Night - Grey.svg';
import iTopBaysOff from'../images/preview/Parking - Grey.svg';
import iTopFacilitiesOff from'../images/preview/Facilities Only - Grey.svg';

import iTopOvernight from'../images/preview/Pay Per Night - Blue.svg';
import iTopBays from'../images/preview/Parking - Blue.svg';
import iTopFacilities from'../images/preview/Facilities Only - Blue.svg';
import iCampraA from'../images/campra/Campra Accredited - Green.svg';
import iCampraX from'../images/campra/Campra Accredited - Grey.svg';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

import iLogo from "../images/logo.png";



const BlueTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #10385A"
    },
    color: '#10385Acc'
  },
  tooltip: {
    backgroundColor: '#10385Add',
    color: '#ffffff',
    border: "1px solid #10385A",
    fontSize: 12,
    fontFamily: "Montserrat",
    fontWeight: 400,

  },
}))(Tooltip);


export default function Mapper() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [currentMarkerKey, setCurrentMarkerKey] = useState(-1);
  const [infoOpen, setInfoOpen] = useState(false);
  const [sites, setSites] = useState([]);
  const [center, setCenter] = useState({ lat: 52.564303191489465, lng: -1.4860456320456625 });
  const [map, setMap] = useState(null);
  const [alertVals, handleAlertChange] = useFormFields({
    title: "",
    message: "",
  });

  const UK_BOUNDS = {
    south: 49.79129622491783,
    west: -10.795963172325314,
    north: 58.84452996184098,
    east: 2.1239587026746864
  };
  const RESTRICT_BOUNDS = {south: 10.994892342824796, west: -39.88202649919864, north: 74.86496038005808, east: 27.61797350080136};

  const mapOptions = {
    restriction: {
      latLngBounds: RESTRICT_BOUNDS,
    },
    mapTypeControl: true,
    streetViewControl: false,
  };


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
  })


  const onMapLoad = React.useCallback(function callback(map) {
    map.fitBounds(UK_BOUNDS);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

function onCenterChanged() {
  if (map) {
    const bounds=map.getBounds();
    if (bounds) {
      //console.log(bounds.toJSON());
    }
  } else {
    //console.log("!");
  }
}

  const onInfoLoad = infoBox => {
    //console.log('infoBox: ', infoBox)
  }
  const onMarkerLoad = marker => {
    //console.log('marker: ', marker)
  }




  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    React.useEffect(() => {
      const handleWindowResize = () => {setWidth(window.innerWidth);setHeight(window.innerHeight);};
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
    return { width, height };
  }
  const { width, height } = useViewport();
  const breakpoint = 600;


  const containerStyle = {
    width: '100%',
    height: height-75
  };


const linkHoverStyle={
  link: {
      color: "#00ff00",
      "&:hover": {
          color: "#000000",
          textDecoration: "underline #000000"
      }
  }
}





useEffect(() => {
  onLoad();
}, []);





/*
function getSites() {
  return API.get("aires", `/sites/ALL/DETAILS`);
}
  */

function getAllSensorData(esk) {
  if (esk==null) {
    return API.get("aires", `/sites/ALL/DETAILS`);
  } else {
    return API.get("aires", `/sites/ALL/DETAILS?esk=` + esk +``);
  }
}

async function getData(esk) {
  const data = await getAllSensorData(JSON.stringify(esk));
  //data.data.map((site, index, array)=>{console.log(site.content.siteName,site.content.location);});
    

  
  return data;//{data: extractData(data), esk: data.data.LastEvaluatedKey};
}




async function onLoad() {
  setIsLoading(true);
  try {
    //const result = await getSites();

    let rslt = await getData(null);
    if (rslt.status && rslt.data) {
      let arr = rslt.data;
      //console.log(arr);
      setSites(arr);
      while (rslt.lek) {
        rslt = await getData(rslt.lek);
        arr.push(...rslt.data);
        setSites(arr);
        //console.log(arr);
      }
      //console.log(arr);
      //setSites(arr);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      alertVals.title="AireStop - Oops!";
      alertVals.message="Unable to retrieve sites, please check your internet connection and try later.";
      setAlertOpen(true);
    }
/*
    if (result.status && result.data) {
      setSites(result.data);
      console.log(result.data);
      result.data.map((site) => {
        console.log(site.content.siteName, site.itemStatus, site.itemStatus2, site.content.location);
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      alertVals.title="AireStop - Oops!";
      alertVals.message="Unable to retrieve sites, please check your internet connection and try later.";
      setAlertOpen(true);
    }
      */
  } catch (e) {
    history.push("/");
  }

}







function handleAlertClose() {
  setAlertOpen(false);
};
function handleSiteClick(e) {
  e.preventDefault();
  history.push("/sites/" + sites[currentMarkerKey].itemCode);
};


const onMapClick = (...args) => {
  setInfoOpen(false);
}

const onMarkerClick = (e) => {
  setInfoOpen(true);
}
const onCloseClick = () => {
  setInfoOpen(false);
}
function onToggleOpen(key) {
  setInfoOpen(false);
  setCurrentMarkerKey(key);
  setInfoOpen(true);

}


        function renderLander() {
          return (
            <div  className={"lander"}>
            {isLoaded?
            <GoogleMap
              mapContainerStyle={containerStyle}
              onLoad={onMapLoad}
              onUnmount={onUnmount}
              onClick={onMapClick}
              options = {mapOptions}
            >
              <>
              {sites && sites.map((site, i) =>
              
              site.content.location&&site.itemCode!="G10203"?<Marker onClick={() => onToggleOpen(i)} key={i} icon={{url: pinIcon, size: {width: 40, height: 40}, origin: {x: 0, y: 0}, anchor: {x: 20, y: 35}, scaledSize: {width: 40, height: 40}}} position={site.content.location} title={site.content.siteName}/>:<></>
           )}



{infoOpen?


              <InfoBox

                onLoad={onInfoLoad}
                options={{ closeBoxURL: '', enableEventPropagation: false, pixelOffset: new window.google.maps.Size(-170,-7), alignBottom: true, fixedWidthSet: false, maxWidth: "200px"}}
                closeBoxMargin={0}
                position={sites[currentMarkerKey].content.location}
                onCloseClick={onCloseClick}
              >




<>
<Box p={1}>
              <Paper  elevation={3}>
<Box display="flex" alignItems="center">
              <Box sx={{ flexGrow: 1 }}>

                <img
    src={iLogo}
    width="auto"
    height="20"

    style={{margin: 8}}
    alt="AireStop"
    />
</Box>
<Box alignItems="center" p={1}>

<Chip size="small" color="primary" deleteIcon={<LaunchRoundedIcon />} onDelete={handleSiteClick} onClick={handleSiteClick} label={sites[currentMarkerKey].itemCode}/>


</Box>
</Box>
<Box px={1}><Divider style={{height:"2px", backgroundColor:"#10385A"}}/> </Box>
              <Box p={1}>
              <Box display="flex" flexWrap="wrap" my={0}  mx={0} alignItems='top'>

              <Box display="flex" flexWrap="wrap" my={0}  ml={-0.1} alignItems='top'>

              <Link  style={{color: "#10385A"}} href="" onClick={handleSiteClick} >
                <h4>{sites[currentMarkerKey].content.siteName}</h4>
              </Link>
              </Box>
              </Box>


              <Paper variant="outlined" elevation={0} >
              <Grid container justifyContent="center" alignItems='center' spacing={0}>
              <Grid key={1} item>
                <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"AireStop is "+(sites[currentMarkerKey].itemStatus > 0?"Open":"Closed")}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3} mr={1} alignItems='center'><FiberManualRecordIcon style={sites[currentMarkerKey].itemStatus > 0?{ color: green[700], height: '20'}:{ color: red[700], height: '20' }} />{sites[currentMarkerKey].itemStatus > 0?'Open':'Closed'}</Box></BlueTooltip>
              </Grid>
              <Grid key={2} item>
                <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Overnight Parking (including facilities use)"}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3}  mx={1} alignItems='center'><img src={sites[currentMarkerKey].content.priceStay==null || sites[currentMarkerKey].content.priceStay==""?iTopOvernightOff:iTopOvernight} width='auto' height='20'/>&nbsp;{(sites[currentMarkerKey].content.priceStay==null || sites[currentMarkerKey].content.priceStay=="")?"":(sites[currentMarkerKey].content.priceStay>0?"£"+sites[currentMarkerKey].content.priceStay:"free")}</Box></BlueTooltip>
              </Grid>
              <Grid key={3} item>
                <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Facilities use only"}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3}  mx={1} alignItems='center'><img src={sites[currentMarkerKey].content.priceFacilities==null || sites[currentMarkerKey].content.priceFacilities==''?iTopFacilitiesOff:iTopFacilities} width='auto' height='20'/>&nbsp;{sites[currentMarkerKey].content.priceFacilities==null || sites[currentMarkerKey].content.priceFacilities==''?"":(sites[currentMarkerKey].content.priceFacilities>0?"£"+sites[currentMarkerKey].content.priceFacilities:"free")}</Box></BlueTooltip>
              </Grid>
              <Grid key={4} item>
                <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"No. Of Parking Bays"}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3}  mx={1} alignItems='center'><img src={sites[currentMarkerKey].content.bays>0?iTopBays:iTopBaysOff} width='auto' height='18'/>&nbsp;{sites[currentMarkerKey].content.bays>0?sites[currentMarkerKey].content.bays:""}</Box></BlueTooltip>
              </Grid>
              <Grid key={5} item>
                <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"CAMpRA have"+(sites[currentMarkerKey].accreditations && sites[currentMarkerKey].accreditations.length>0?" ":" not ")+"accredited this Aire"}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3}  ml={1} alignItems='center'><img src={sites[currentMarkerKey].accreditations && sites[currentMarkerKey].accreditations.length>0?iCampraA:iCampraX} width='auto' height='14'/></Box></BlueTooltip>
              </Grid>
               </Grid>
            </Paper>


        <br/>
        <Box mb={0}  display="inline-block" bgcolor="background.paper"><img src={sites[currentMarkerKey].image?sites[currentMarkerKey].image:defaultSiteImage} width={/*breakpoint>width?*/'100%'/*:'620px'*/} height="auto" style={{borderRadius: '4px', maxWidth:'320px', maxHeight:'240px'}}/></Box>

        </Box>
</Paper>
</Box>

</>








              </InfoBox>

: <></>}

{isLoading?
              <InfoBox

              onLoad={onInfoLoad}
              options={{ closeBoxURL: '', enableEventPropagation: false, pixelOffset: new window.google.maps.Size(-170,-7), alignBottom: true, fixedWidthSet: false, maxWidth: "200px"}}
              closeBoxMargin={0}
              position={{ lat: 52.564303191489465, lng: -1.4860456320456625 }}
              onCloseClick={onCloseClick}
            >
            <div id="splash" style={{zIndex:999}}>
            <center id="splash">
              <img src={spinner} height="30px" width="auto"/>
            </center>
          </div>
          </InfoBox>
:<></>}

              </>
            </GoogleMap>
         : <></>
}

            </div>
          )
        }


        function renderLoader() {
          return (
            <div id="splash" style={{paddingTop: 100, zIndex:999}}>
              <center id="splash">
                <img src={spinner} height="30px" width="auto"/>
              </center>
            </div>
          );
        }

        return (
          <div className="Mapper">
            {isPageLoading?renderLoader():renderLander()}
          <Dialog
          open={alertOpen}
          fullWidth={false}
          maxWidth = {'md'}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
          {alertVals.title}
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          {alertVals.message}
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleAlertClose} color="secondary">
          Ok
          </Button>
          </DialogActions>
          </Dialog>
          </div>
        );
      }
