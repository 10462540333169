import { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./TextWrap.module.css";
import { Link } from 'react-router-dom';

import { useHistory } from "react-router-dom";

const TextWrap = ({ className = "", simpleAndCleanUIs, propPadding }) => {
  const history= useHistory();
  const textWrapStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);


  

  function handleLogin(event) {
    event.preventDefault();
    history.push("/login");
    

  }

  function handleSignup(event) {
    event.preventDefault();
    history.push("/signup");
  }

  return (
    <div
      className={[styles.textwrap, className].join(" ")}
      style={textWrapStyle}
    >
      <div className={styles.info}>
        <div className={styles.text}>
          <h1 className={styles.simpleAndClean}>{simpleAndCleanUIs}</h1>
          <div className={styles.powerfulYetClean}>
            Start your hosting journey today by joining a growing community of
            hosts and tap into the booming motorhome market
          </div>
        </div>
        <button className={styles.ctaGroup} onClick={handleSignup}>
          <div className={styles.locobutton1}>
            <div className={styles.button1}>Become a Host</div>
          </div>
        </button>
      </div>

      
      <div className={styles.text}>
      <h1 className={styles.simpleAndClean}>&nbsp;</h1>
          <div className={styles.powerfulYetClean}>
            Already signed up? <Link  onClick={handleLogin} >Click here to login.</Link>
          </div>
      </div>
      </div>
      

    
  );
};

TextWrap.propTypes = {
  className: PropTypes.string,
  simpleAndCleanUIs: PropTypes.string,

  /** Style props */
  propPadding: PropTypes.any,
};

export default TextWrap;
