import Infomation from "./Infomation";
import PropTypes from "prop-types";
import styles from "./ContentComponentWhatIsAnAire.module.css";

const ContentComponentWhatIsAnAire = ({ className = "" }) => {
  return (
    <section
      className={[styles.contentcomponentWhatisanaire, className].join(" ")}
    >
      <div className={styles.container}>
        <div className={styles.contentwarp}>
          <div className={styles.graphicwrapper}>
            <div className={styles.contentgraphic}>
              <div className={styles.stopover}>
                <div className={styles.headertext}>
                  <h1 className={styles.aire}>Aire</h1>
                  <div className={styles.air}>[air]</div>
                </div>
                <div className={styles.stopoverChild} />
                <i className={styles.noun}>Noun</i>
                <div className={styles.aDesignatedStopoverContainer}>
                  <ol className={styles.aDesignatedStopoverAreaFor}>
                    <li>
                      A designated stopover area for Motorhomes and Campervans.
                    </li>
                  </ol>
                </div>
                <div className={styles.aPlaceWhereContainer}>
                  <ol className={styles.aPlaceWhereMotorhomesAndC}>
                    <li>
                      A place where motorhomes and campervans can access
                      essential services (For example: replenish water and empty
                      waste tanks).
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <Infomation
            zermattCardTitle="What is an aire?"
            zermattCardDescription={`Stemming from the French "Aire de Service" or "Aire de Repos," which translates to "service area" or "rest area," an Aire is a designated stopover location for motorhomes and campervans. It provides essential services such as overnight parking, fresh water, and waste disposal facilities. Aires can range from simple parking areas to more developed sites with additional amenities. They offer users a convenient, often cost-effective alternative to traditional campsites, allowing for flexible, independent travel experiences.`}
          />
        </div>
      </div>
    </section>
  );
};

ContentComponentWhatIsAnAire.propTypes = {
  className: PropTypes.string,
};

export default ContentComponentWhatIsAnAire;
