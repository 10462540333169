import PropTypes from "prop-types";
import styles from "./ContentComponentOurStrategy.module.css";

const ContentComponentOurStrategy = ({ className = "" }) => {
  return (
    <section
      className={[styles.contentcomponentOurstrategy, className].join(" ")}
    >
      <div className={styles.container}>
        <div className={styles.contentwarp}>
          <div className={styles.infomation}>
            <div className={styles.textWrapper}>
              <h1 className={styles.zermattCardTitle}>Our Strategy</h1>
              <div className={styles.zermattCardDescriptionContainer}>
                <p className={styles.toAchieveOur}>
                  To achieve our mission, we focus on three key areas:
                </p>
                <p className={styles.blankLine}>&nbsp;</p>
                <ol className={styles.easeForLandownersWereCom}>
                  <li className={styles.easeForLandownersWereCom1}>
                    <span className={styles.easeForLandowners}>
                      Ease for Landowners:
                    </span>
                    <span>
                      {" "}
                      We're committed to making it as easy as possible for
                      landowners to open and operate an aire. Our services
                      provide comprehensive support, guidance, and tools to
                      simplify the process of opening and managing an aire.
                    </span>
                  </li>
                  <li className={styles.simplicityOurPlatformIsDe}>
                    <span className={styles.simplicity}>{`Simplicity: `}</span>
                    <span className={styles.ourPlatformIs}>
                      Our platform is designed for ease of use, allowing guests
                      to find and book Aires quickly, often with same-day or
                      next-day availability.
                    </span>
                  </li>
                  <li>
                    <span className={styles.community}>{`Community: `}</span>
                    <span className={styles.weFosterA}>
                      We foster a respectful community of guests and hosts,
                      promoting the 'Leave No Trace' ethos and encouraging
                      positive interactions between visitors and local
                      communities.
                    </span>
                  </li>
                </ol>
              </div>
            </div>
            <div className={styles.cta}>
              <div className={styles.ctaChild} />
              <div className={styles.locobutton}>
                <div className={styles.button}>Button</div>
                <img className={styles.icon} alt="" src="/icon2.svg" />
              </div>
            </div>
          </div>
          <div className={styles.graphicwrapper}>
            <div className={styles.illustrations}>
              <img
                className={styles.undrawNavigationReWxx41Icon}
                loading="lazy"
                alt=""
                src="/undraw-navigation-re-wxx4-1.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContentComponentOurStrategy.propTypes = {
  className: PropTypes.string,
};

export default ContentComponentOurStrategy;
