import PropTypes from "prop-types";
import styles from "./ContentComponentHowComeItsFre.module.css";

const ContentComponentHowComeItsFre = ({ className = "" }) => {
  return (
    <section
      className={[styles.contentcomponentHowcomeitsfre, className].join(" ")}
    >
      <div className={styles.container}>
        <div className={styles.contentwarp}>
          <div className={styles.infomation}>
            <div className={styles.textWrapper}>
              <h1 className={styles.zermattCardTitle}>How come its free?</h1>
              <h3 className={styles.zermattCardDescriptionContainer}>
                <p className={styles.ourObjectiveIs}>
                  Our objective is to expand the network of aires, making travel
                  more accessible and enjoyable. To support this goal, we've
                  made our platform completely free to hosts. By removing
                  financial barriers, we hope to encourage more people to open
                  an aire, creating an increasingly diverse network of
                  stopovers.
                </p>
                <p className={styles.blankLine}>&nbsp;</p>
                <p className={styles.weSustainOur}>
                  We sustain our platform by adding a small service fee to each
                  guest transaction. This fee helps us maintain and improve our
                  platform, provide support, and expand our network. By keeping
                  our platform free for hosts, we create a win-win situation:
                  hosts can monetise their land easily, and motoroamers benefit
                  from more stopover options.
                </p>
              </h3>
            </div>
            <div className={styles.cta}>
              <div className={styles.ctaChild} />
              <div className={styles.locobutton}>
                <div className={styles.button}>Learn more</div>
                <img className={styles.icon} alt="" src="/icon2.svg" />
              </div>
            </div>
          </div>
          <div className={styles.graphicwrapper}>
            <div className={styles.illustrations}>
              <img
                className={styles.undrawFestivitiesTvvj1Icon}
                loading="lazy"
                alt=""
                src="/undraw-festivities-tvvj-1.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContentComponentHowComeItsFre.propTypes = {
  className: PropTypes.string,
};

export default ContentComponentHowComeItsFre;
