import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './CalendarView.css';

const CalendarView = ({ bookingData, setBookingData, setBookingState }) => {
  const history = useHistory();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDates, setSetSelectedDates] = useState(bookingData.selectedDates);


  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

  const handleDateSelect = (day) => {
    console.log(day);
    const selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day,12);
    const dateString = selectedDate.toISOString().split('T')[0];
    const newSelectedDates = [...selectedDates];
    console.log(selectedDate);
    console.log(dateString);
    console.log(newSelectedDates);
    console.log(firstDayOfMonth);
    console.log(daysInMonth);

    const index = newSelectedDates.indexOf(dateString);

    if (index > -1) {
      newSelectedDates.splice(index, 1);
    } else {
      newSelectedDates.push(dateString);
    }

    //setBookingData({ ...bookingData, selectedDates: newSelectedDates });
    setSetSelectedDates(newSelectedDates);
  };







  const handleMonthChange = (increment) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + increment);
    setCurrentDate(newDate);
  };

  const handleConfirm = () => {
    if (selectedDates.length > 0) {
      //history.push('/user-details');
      setBookingData({ ...bookingData, selectedDates: selectedDates });
      setBookingState({current:"userdetails", prev:"calendarview"});
  }
  };

  const handleDateView = () => {
    //history.push('/calendar');
    setBookingData({ ...bookingData, selectedDates: selectedDates });
    setBookingState({current:"dateselection", prev:"calendarview"});

  };


  return (
    <div className="calendar-view">
       <button className="date-selection-btn" onClick={handleDateView}>Day View</button>
     <div className="calendar-header">
        <button onClick={() => handleMonthChange(-1)}>&lt;</button>
        <h2>{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</h2>
        <button onClick={() => handleMonthChange(1)}>&gt;</button>
      </div>
      <div className="calendar-grid">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="calendar-day day-name">{day}</div>
        ))}
        {[...Array(firstDayOfMonth).keys()].map(i => (
          <div key={`empty-${i}`} className="calendar-day empty"></div>
        ))}
        {[...Array(daysInMonth).keys()].map(i => {
          const day = i + 1;
          const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day,12).toISOString().split('T')[0];
          return (
            <div
              key={day}
              className={`calendar-day ${selectedDates.includes(date) ? 'selected' : ''}`}
              onClick={() => handleDateSelect(day)}
            >
              {day}
              <span className="tooltiptext">{i%2==0?"3 bays available":"No available bays"}</span>
            </div>
          );
        })}
      </div>
      <button className="confirm-dates-btn" onClick={handleConfirm}>Confirm Dates</button>
    </div>
  );
};



function dateRange(startDate, endDate, steps = 1) {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateArray.push(currentDate.toISOString().split('T')[0]);
    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
  }
  return dateArray;
}

export default CalendarView;
