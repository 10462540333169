import PropTypes from "prop-types";
import styles from "./ContentComponentHowAireStopSu.module.css";

const ContentComponentHowAireStopSu = ({ className = "" }) => {
  return (
    <section
      className={[styles.contentcomponentHowairestopsu, className].join(" ")}
    >
      <div className={styles.container}>
        <div className={styles.contentwarp}>
          <div className={styles.graphicwrapper}>
            <div className={styles.illustrations}>
              <img
                className={styles.undrawSharedGoalsReJvqd1Icon}
                loading="lazy"
                alt=""
                src="/undraw-shared-goals-re-jvqd-1-1.svg"
              />
            </div>
          </div>
          <div className={styles.infomation}>
            <div className={styles.textWrapper}>
              <h1 className={styles.zermattCardTitle}>
                How AireStop supports you
              </h1>
              <div className={styles.zermattCardDescriptionContainer}>
                <p className={styles.wereCommittedTo}>
                  We're committed to making hosting as simple and rewarding as
                  possible. Our team provides comprehensive support at every
                  stage of your hosting journey:
                </p>
                <p className={styles.blankLine}>&nbsp;</p>
                <p className={styles.design}>Design</p>
                <p className={styles.weOfferExpert}>
                  We offer expert guidance on optimal aire layouts and
                  amenities.
                </p>
                <p className={styles.blankLine1}>&nbsp;</p>
                <p className={styles.construction}>Construction</p>
                <p className={styles.weProvideRecommendations}>
                  We provide recommendations for cost-effective, high-quality
                  facility installations.
                </p>
                <p className={styles.blankLine2}>&nbsp;</p>
                <p className={styles.opening}>Opening</p>
                <p className={styles.weHelpYou}>
                  We help you create an attractive profile and set-up ready to
                  welcome your first guest.
                </p>
                <p className={styles.blankLine3}>&nbsp;</p>
                <p className={styles.businessAsUsual}>Business-as-usual</p>
                <p className={styles.ourUserFriendlyPlatform}>
                  Our user-friendly platform streamlines bookings, payments, and
                  guest communications.
                </p>
                <p className={styles.blankLine4}>&nbsp;</p>
                <p className={styles.withAirestopYoure}>
                  With AireStop, you're never alone in your hosting adventure.
                </p>
              </div>
            </div>
            <div className={styles.cta}>
              <div className={styles.ctaChild} />
              <div className={styles.locobutton}>
                <div className={styles.button}>Learn more</div>
                <img className={styles.icon} alt="" src="/icon2.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContentComponentHowAireStopSu.propTypes = {
  className: PropTypes.string,
};

export default ContentComponentHowAireStopSu;
