import React, { useState , useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
//import MenuIcon from '@material-ui/icons/Menu';
//import SearchIcon from '@material-ui/icons/Search';
//import DirectionsIcon from '@material-ui/icons/Directions';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 0.8,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  paper: {
    opacity: 0.5,
  }
}));

export default function EditInput(props) {
  const [formData, setFormData] = useState({ val: '', other: '' });
  const handleChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (props.onChange) {
      props.onChange(formData)
    }
  }, [formData.val, formData.other])

//console.log(props);


  const [input, setInput] = useState('');
  const classes = useStyles();


  const onChange = (e) => {
      setInput(e.currentTarget.value);
  };

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        inputProps={{ 'aria-label': 'enter site code' }}
        name="val"
        value={props.value}
        disabled={true}
        onChange={handleChange}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton color="primary" className={classes.iconButton} aria-label="directions"  disabled={props.iDisabled} onClick={props.onClick}>
        <EditRoundedIcon/>
      </IconButton>
    </Paper>
  );
}
